import PropTypes from "prop-types";

const NewPlacement = ({ currentUser, relatedCase }) => (
  <h1>
    hello {currentUser.fullName}! {console.log(relatedCase)}
  </h1>
);
NewPlacement.propTypes = {
  currentUser: PropTypes.object,
  relatedCase: PropTypes.object,
};

export default NewPlacement;
