import {
  Flex,
  Text,
  Button,
  If,
  InputFilterable,
  InputText,
  InputCheckbox,
  SurfaceForm,
  InputDate,
  InputPhone,
  InputRadioGroup,
  InputTextarea,
  Actions,
  Switch,
  InputTextWithAddressAutocomplete,
  InputCheckboxGroup,
  InputFilterableLegacyAjax,
  InputDropdown,
} from "@heart/components";
import LANGUAGES_OPTIONS from "@utils/languages";
import post from "@utils/post";
import protectedTribes from "@utils/protectedTribes";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import EthnicityAndRaceWrapper from "@components/intake_ethnicity/EthnicityAndRaceWrapper";

import styles from "./NewPersonForm.module.scss";
import PersonCard from "./PersonCard";

const NewPersonForm = ({
  onPersonAdd,
  formState,
  buttonClass,
  hasNotice = false,
  updateWindowsLocation,
  askMilitaryPersonnelInfo = true,
}) => {
  const languageInputOptions = Object.entries(LANGUAGES_OPTIONS).map(
    ([k, v]) => ({ label: v, value: k })
  );
  const [submitting, setSubmitting] = useState(false);
  const [isDobEstimate, setIsDobEstimate] = useState(false);
  const [isDeceased, setIsDeceased] = useState(false);
  const [schoolId, setSchoolId] = useState("");
  const [schoolAddress, setSchoolAddress] = useState("");
  const [isAddressUnknown, setIsAddressUnknown] = useState(false);
  const [isAddingNewPerson, setIsAddingNewPerson] = useState(false);
  const [isVictimInSchool, setIsVictimInSchool] = useState("");
  const [isVictim, setIsVictim] = useState();
  const [isTribalAffiliated, setIsTribalAffiliated] = useState();
  const [isMilitaryPersonnel, setIsMilitaryPersonnel] = useState();
  const [address, setAddress] = useState({
    type: [],
    addressLine1: "",
    countryCode: "US",
    postalCode: "",
    primary: false,
    inactive: false,
    city: "",
    primarySubdivisionCode: "",
    primarySubdivisionName: "",
    secondarySubdivision: "",
  });

  const updateFields = updates => {
    setAddress({ ...address, ...updates });
  };

  const handleDeceasedToggle = () => {
    setIsDeceased(prev => !prev);
  };

  const handleAddressUnknownToggle = () => {
    setIsAddressUnknown(prev => !prev);
  };

  const handleSchoolChange = value => {
    setSchoolId(value.id);
    setSchoolAddress(value.value);
  };

  useEffect(() => {
    if (!isVictim) {
      setIsVictimInSchool(false);
    }
  }, [isVictim]);

  useEffect(() => {
    if (!isAddingNewPerson) {
      setIsDobEstimate(false);
      setAddress({
        type: [],
        addressLine1: "",
        countryCode: "US",
        postalCode: "",
        primary: false,
        inactive: false,
        city: "",
        primarySubdivisionCode: "",
        primarySubdivisionName: "",
        secondarySubdivision: "",
      });
      setSchoolAddress("");
      setIsVictimInSchool("");
    }
  }, [isAddingNewPerson]);

  const onSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData(e.target);

    const personData = Object.entries(Object.fromEntries(formData)).reduce(
      (acc, [key, value]) => {
        if (key === "role[]") {
          acc.role = formData.getAll(key);
        } else if (
          key === "isNameUnknown" ||
          key === "isDobEstimate" ||
          key === "personIsDeceased"
        ) {
          acc[key] = value === "on";
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    personData.address = address;
    personData.schoolId = schoolId;
    const { personDatabaseId, personId } = await post("/persons", personData);
    onPersonAdd({ personDatabaseId, personId, personData });
    setIsAddingNewPerson(false);
    setIsAddressUnknown(false);
    setSubmitting(false);
    if (updateWindowsLocation) window.location = updateWindowsLocation;
  };

  const resetFormStates = () => {
    setIsDobEstimate(false);
    setIsDeceased(false);
    setSchoolAddress("");
    setIsAddressUnknown(false);
    setIsAddingNewPerson(false);
    setIsVictimInSchool("");
    setIsVictim(false);
    setAddress({
      type: [],
      addressLine1: "",
      countryCode: "US",
      postalCode: "",
      primary: false,
      inactive: false,
      city: "",
      primarySubdivisionCode: "",
      primarySubdivisionName: "",
      secondarySubdivision: "",
    });
  };

  return (
    <React.Fragment>
      <If condition={formState.persons.length > 0}>
        <Flex gap="300" column>
          {formState.persons.map(person => (
            <PersonCard
              hasNotice={hasNotice}
              key={person.name + person.lastName}
              person={person}
              askMilitaryPersonnelInfo={askMilitaryPersonnelInfo}
            />
          ))}
        </Flex>
      </If>
      <If condition={isAddingNewPerson}>
        <SurfaceForm
          title="New Person"
          hideTitle
          onSubmit={onSubmit}
          actions={
            <Actions
              isSubmitting={submitting}
              cancelAction={resetFormStates}
              primaryText="Save"
            />
          }
        >
          <Flex gap="200" column className={styles.personFormContainer}>
            <Text textStyle="emphasis-200" textColor="neutral-600">
              New Person
            </Text>
            <InputCheckboxGroup
              id="role"
              label="Role"
              name="role"
              onChange={role => {
                setIsVictim(role.includes("victim"));
              }}
              required
              values={[
                {
                  label: "Alleged Victim",
                  value: "victim",
                },
                {
                  label: "Alleged Abuser",
                  value: "alleged-abuser",
                },
                {
                  label: "Caretaker",
                  value: "caretaker",
                },
                {
                  label: "Other Child in the Home",
                  value: "other-child-in-the-home",
                },
                {
                  label: "Other Adult in the Home",
                  value: "other-adult-in-the-home",
                },
                {
                  label: "Other Person not in the Home",
                  value: "other-person-not-in-the-home",
                },
              ]}
            />
            <Flex row gap="200" className={styles.rowPersonalInformation}>
              <InputText label="First Name" required name="name" />
              <InputText label="Middle Name" name="middleName" />
              <InputText label="Last Name" required name="lastName" />
            </Flex>
            <InputCheckbox
              id="name-unknown"
              label="Name is unknown"
              name="isNameUnknown"
            />

            <Flex row gap="400">
              <Flex column gap="200">
                <InputDate required label="Date of birth" name="dob" />
                <InputCheckbox
                  id="isDobEstimate"
                  label="Date of Birth is estimated"
                  name="isDobEstimate"
                  value={isDobEstimate}
                  onChange={setIsDobEstimate}
                  htmlValue={isDobEstimate}
                />
              </Flex>

              <InputFilterable
                id="sex"
                label="Sex"
                name="sex"
                values={[
                  {
                    label: "Male",
                    value: "Male",
                  },
                  {
                    label: "Female",
                    value: "Female",
                  },
                  {
                    label: "Intersex",
                    value: "Intersex",
                  },
                  {
                    label: "Unknown",
                    value: "Unknown",
                  },
                ]}
              />
            </Flex>

            <Switch
              id="personIsDeceased"
              name="personIsDeceased"
              label="Person is deceased"
              value={isDeceased}
              onChange={handleDeceasedToggle}
            />

            {isDeceased && (
              <InputDate required label="Date deceased" name="dateDeceased" />
            )}

            <If condition={!isAddressUnknown}>
              <InputTextWithAddressAutocomplete
                id="address"
                label={"Address"}
                type="text"
                name={"addressLine1"}
                value={[
                  address.addressLine1,
                  address.city,
                  address.primarySubdivisionCode,
                  address.postalCode,
                ]
                  .filter(n => n)
                  .join(" ")}
                onAddressPicked={pickedAddress =>
                  setAddress({ ...address, ...pickedAddress })
                }
                autocompleteEnabled={true}
                onChange={val => updateFields(val)}
              />
            </If>

            <Switch
              id="isAddressUnknown"
              name="isAddressUnknown"
              label="Address is Unknown"
              value={isAddressUnknown}
              onChange={handleAddressUnknownToggle}
            />

            <Flex row gap="200">
              <InputPhone label="Phone number" name="phoneNumber" />
            </Flex>

            <EthnicityAndRaceWrapper
              useRacesInput
              ethnicitiesInputName="ethnicity"
              racesInputName="race"
              isRequired
              selectedEthnicities={{}}
              selectedRaces={{}}
            />

            <If condition={isVictim}>
              <InputRadioGroup
                id="isVictimInSchool"
                name="isVictimInSchool"
                label="Is the victim enrolled in school?"
                required
                values={["Yes", "No", "Unknown"]}
                onChange={setIsVictimInSchool}
              />
            </If>

            <If condition={isVictim && isVictimInSchool === "Yes"}>
              <InputFilterableLegacyAjax
                id="school"
                label="School"
                name="school"
                onChange={handleSchoolChange}
                params={{}}
                previousState={{}}
                url="/schools"
                value={schoolAddress}
              />
            </If>

            <InputRadioGroup
              id="tribalAffiliation"
              name="tribalAffiliation"
              label="Is this person an enrolled member of a tribe or band?"
              required
              value={isTribalAffiliated}
              onChange={setIsTribalAffiliated}
              values={["Yes", "No", "Unknown"]}
            />

            <If condition={isTribalAffiliated === "Yes"}>
              <InputFilterable
                id="tribeBandName"
                label="Tribe or Band Name"
                description="If the tribe or band is not listed, please contact Binti Support"
                name="tribeBandName"
                values={protectedTribes.map(tribe => ({
                  label: tribe,
                  value: tribe,
                }))}
              />
              <InputText
                label="Tribal Identification (CDIB, CIB, or equivalent)"
                name="tribalIdentification"
              />
            </If>

            <If condition={askMilitaryPersonnelInfo}>
              <InputRadioGroup
                id="militaryPersonnel"
                name="militaryPersonnel"
                label="Is this person Military personnel"
                required
                values={["Yes", "No", "Unknown"]}
                value={isMilitaryPersonnel}
                onChange={setIsMilitaryPersonnel}
              />
              <If condition={isMilitaryPersonnel === "Yes"}>
                <InputDropdown
                  id="militaryBranch"
                  label="Military Branch"
                  name="militaryBranch"
                  values={[
                    "Army",
                    "Navy",
                    "Air Force",
                    "Marine Corps",
                    "Coast Guard",
                    "Space Force",
                    "National Guard",
                    "Other",
                  ]}
                />
              </If>
            </If>

            <InputFilterable
              id="primaryLanguage"
              name="primaryLanguage"
              label="Primary Language"
              values={languageInputOptions}
            />
            <InputFilterable
              id="secondaryLanguage"
              name="secondaryLanguage"
              label="Secondary Language"
              values={languageInputOptions}
            />
            <InputRadioGroup
              id="needsInterpreter"
              name="needsInterpreter"
              label="Needs interpreter?"
              values={["Yes", "No"]}
            />

            <InputTextarea
              label="Physical descriptor"
              name="physicalDescriptor"
              description="Details useful in identifying the person"
            />
          </Flex>
        </SurfaceForm>
      </If>
      <Button
        variant="secondary"
        disabled={isAddingNewPerson}
        onClick={() => setIsAddingNewPerson(true)}
        className={buttonClass}
      >
        Add Persons to Report
      </Button>
    </React.Fragment>
  );
};

NewPersonForm.propTypes = {
  formState: PropTypes.object.isRequired,
  askMilitaryPersonnelInfo: PropTypes.bool,
  onPersonAdd: PropTypes.func.isRequired,
  buttonClass: PropTypes.string,
  hasNotice: PropTypes.bool,
  updateWindowsLocation: PropTypes.string,
};

export default NewPersonForm;
