import { clickable } from "@heart/core/clickable.module.scss";
import classnames from "classnames";
import { ElementType, forwardRef } from "react";

import styles from "./Flex.module.scss";

type CommonFlexProps = {
  mobileColumn?: boolean;
  tabletColumn?: boolean;
  gap?: "0" | "50" | "100" | "200" | "300";
  justify?: "start" | "end" | "center" | "space-between";
  align?: "center" | "start" | "end";
  wrap?: boolean;
  as?: ElementType;
  className?: string;
  grow?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
};

type FlexRowProps = {
  row: true;
  column?: never;
};

type FlexColumnProps = {
  column: true;
  row?: never;
};

type FlexProps =
  | (CommonFlexProps & FlexRowProps)
  | (CommonFlexProps & FlexColumnProps);

/**
 * Flexbox convenience component.  Most of the `<Flex>` prop names and
 * values should be similar to the flex properties available in CSS.
 *
 * Not all Flexbox options are available, and that's by design!
 * We want to achieve consistency in our UX by limiting the options available.
 *
 * You can always fallback to using Flexbox 'manually' over in
 * your component's `module.scss` by setting Flex properties yourself -
 * but if you notice yourself doing this repeatedly for the same
 * outcome, raise it to the FE Guild and we might opt to add more props.
 *
 * The `<Flex/>` component accepts a `ref` to enable things like keyboard functionality
 *
 * For those new to Flexbox, here's a cheatsheet:
 *
 * https://css-tricks.com/snippets/css/a-guide-to-flexbox/
 *
 * @param row Whether or not this Flex is a row.  Mutually exclusive with `column`.
 * @param column Whether or not this Flex is a column.  Mutually exclusive with `row`.
 * @param mobileColumn If true, turns this row into a column on mobile
 * @param tabletColumn If true, turns this row into a column on tablet and mobile.
 * @param gap Gap between child elements
 * @param justify Orientation along the main flex axis
 * @param align Orientation along the cross flex axis
 * @param wrap Whether the elements should wrap if the container is too small
 * @param as What kind of DOM element to use.  Defaults to `<div>`
 * @param className Additional classes to customize the appearance of this Container
 * @param grow If we want flex-grow: 1 on this container.
 * @param fullWidth If we want width: 100% on this container.
 */
const Flex = forwardRef(
  (
    {
      row,
      column,
      mobileColumn,
      tabletColumn,
      gap = "100",
      justify = "start",
      align,
      wrap = false,
      as: Component = "div",
      className,
      grow,
      fullWidth,
      ...props
    }: FlexProps,
    ref: React.Ref<typeof Component>
  ) => {
    const isClickable = ["button", "a"].includes(Component.toString());

    return (
      <Component
        ref={ref}
        className={classnames(
          styles.flex,
          styles[`gap-${gap}`],
          styles[`justify-${justify}`],
          {
            [styles[`align-${align}`]]: align,
            [styles.row]: row,
            [styles.column]: column,
            [styles.mobileColumn]: mobileColumn,
            [styles.tabletColumn]: tabletColumn,
            [styles.wrap]: wrap,
            [clickable]: isClickable,
            [styles.grow]: grow,
            [styles.fullWidth]: fullWidth,
          },
          className
        )}
        {...props}
      />
    );
  }
);
Flex.displayName = "Flex";

/**
 * A FlexItem is a child of a Flex container. It can take up a certain amount
 * of space according to the `expand` prop.
 *
 * @param expand How much space this component should take up
 * @param as What kind of DOM element to use.  Defaults to `<div>`
 * @returns
 */
export const FlexItem = ({
  expand = "sm",
  as: Component = "div",
  ...props
}: {
  expand?: "none" | "sm" | "md" | "lg";
  as?: ElementType;
  children?: React.ReactNode;
}) => (
  <Component className={classnames(styles[`expand-${expand}`])} {...props} />
);

export default Flex;
