import { ContentTabs, Surface, Button, Pill } from "@heart/components";
import React from "react";

import styles from "./Related.module.scss";
import RelatedCasesTable from "./RelatedCasesTable";
import { columnsTabOne, dataTabOne, dataTabTwo } from "./mockData";

const RelatedCases = ({ buttonClass, formState }) => (
  <Surface
    title={"Related Cases"}
    sectionId={"related-cases"}
    titleStyle={"emphasis-200"}
    titleColor={"neutral-600"}
    bonusContent={
      formState.persons.length > 0 && (
        <Pill variant={"alert"} text={"2 Related Incidents"} />
      )
    }
    className={styles.surfaceContainer}
  >
    {formState.persons.length > 0 && (
      <ContentTabs
        param="related-cases"
        className={styles.customTab}
        tabs={[
          {
            contents: (
              <RelatedCasesTable columns={columnsTabOne} data={dataTabOne} />
            ),
            count: 2,
            id: "first-tab-related",
            title: "Confirmation",
          },
          {
            contents: (
              <RelatedCasesTable columns={columnsTabOne} data={dataTabTwo} />
            ),
            count: 1,
            id: "second-tab-related",
            title: "Potentially Related",
          },
        ]}
      />
    )}

    <Button variant="secondary" onClick={() => {}} className={buttonClass}>
      Add Related Case
    </Button>
  </Surface>
);

export default RelatedCases;
