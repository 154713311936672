import {
  Button,
  ButtonMenu,
  Flex,
  HeartTable,
  Icons,
  InputText,
  Link,
} from "@heart/components";
import PropTypes from "prop-types";
import { newCaseActivityPath, casePath } from "routes";

import CreateDashboardLayout from "@components/shared/layout/create_dashboard_layout/CreateDashboardLayout";

import styles from "./NewCasePage.module.scss";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const CaseManagementPage = ({ cases }) => (
  <CreateDashboardLayout
    pageTitle={"Case Management"}
    breadcrumb={[
      {
        href: "/",
        label: "Home",
      },
      {
        href: "/case-management",
        label: "Case Management",
      },
    ]}
  >
    <Flex column gap="300">
      <Flex justify="space-between" align="center">
        <div className={styles.searchInputContainer}>
          <Icons.Search />
          <InputText
            id="searchCases"
            name="searchCases"
            placeholder="Search cases"
            className={styles.searchInput}
          />
        </div>
        <Button icon={Icons.Filter} variant="secondary">
          Filter
        </Button>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th> Case ID </Th>
            <Th> Case Name </Th>
            <Th> Case Type </Th>
            <Th> Case Start Date </Th>
            <Th> Worker(s) Assigned </Th>
            <Th> Actions </Th>
          </Tr>
        </Thead>
        <Tbody>
          {cases.map(c => (
            <Tr key={c.referralId}>
              <Td>
                <Link href={casePath(c.referralId)}>{c.referralId}</Link>
              </Td>
              <Td>
                <Link href={casePath(c.referralId)}>
                  {c.referralName?.join(", ")}
                </Link>
              </Td>
              <Td>{c.type}</Td>
              <Td>{c.reportTimestamp?.split(" ")[0]}</Td>
              <Td>
                <Link href="#">{c.workerAssigned}</Link>
              </Td>
              <Td alignRight>
                <ButtonMenu
                  buttonProps={{
                    children: "Actions",
                    iconOnRight: true,
                    icon: Icons.ChevronDown,
                    variant: "secondary",
                  }}
                  linkItems={[
                    {
                      description: "View Case",
                      href: casePath(c.referralId),
                    },
                    {
                      description: "Add Case Activity",
                      href: newCaseActivityPath(c.referralId),
                    },
                  ]}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justify="center">
        <Link>Download CSV</Link>
      </Flex>
    </Flex>
  </CreateDashboardLayout>
);
CaseManagementPage.propTypes = {
  cases: PropTypes.array.isRequired,
};

export default CaseManagementPage;
