import { Surface, Text } from "@heart/components";
import React from "react";

import LayoutSidebarSections from "@components/shared/layout/layout_sidebar_sections/LayoutSidebarSections";

import Dental from "./tiles/Dental";
import Diagnoses from "./tiles/Diagnoses";
import Medical from "./tiles/Medical";
import MedicalInsuranceInformation from "./tiles/MedicalInsuranceInformation";
import Medications from "./tiles/Medications";
import Mental from "./tiles/Mental";
import Visits from "./tiles/Visits";

const links = [
  { href: "#diagnoses", label: "Diagnoses" },
  { href: "#medications", label: "Medications" },
  { href: "#medical", label: "Medical" },
  { href: "#dental", label: "Dental" },
  { href: "#behavioral", label: "Behavioral" },
  { href: "#education", label: "Education" },
];

const WellBeing = () => (
  <LayoutSidebarSections links={links}>
    <Diagnoses />
    <Medications />
    <MedicalInsuranceInformation />
    <Medical />
    <Dental />
    <Mental />
    <Surface title="Education" hideTitle sectionId="education">
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Education
      </Text>
    </Surface>
    <Visits />
  </LayoutSidebarSections>
);

export default WellBeing;
