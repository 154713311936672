/* eslint-disable no-nested-ternary */
import {
  HeartTable,
  Surface,
  Flex,
  Button,
  Text,
  Pill,
  ButtonMenu,
  Icons,
  Link,
} from "@heart/components";
import PropTypes from "prop-types";
import React from "react";
import {
  caseActivityNewPath,
  investigationReviewPath,
  investigationsPath,
} from "routes";

import styles from "../HomePage.module.scss";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

export const formatPill = priorityRecommendation => {
  const { priority, type } = priorityRecommendation;

  if (type === "" || priority === "") {
    return null;
  }

  return (
    <Pill
      variant={
        priority === "R1" ? "warning" : priority === "R2" ? "alert" : "neutral"
      }
      text={priority}
    />
  );
};

export const isNewCaseStatus = status => {
  if (status === "investigation_pending") {
    return true;
  }
  return false;
};

const TableBody = ({ cases }) => (
  <Tbody>
    {cases.map((item, index) => (
      <Tr key={index}>
        <Td>
          <Flex row gap="100" align="center">
            {isNewCaseStatus(item.caseStatus) && (
              <Pill variant={"alert"} text="New" />
            )}
            <Link href={investigationReviewPath(item.referralId)}>
              {item.referralName && item.referralName[0]}
            </Link>
          </Flex>
        </Td>
        <Td>
          <Text className={styles.greyLinkText}>{item.reportTimestamp}</Text>
        </Td>
        <Td> {formatPill(item.priorityRecommendation)} </Td>
        <Td> {item.approvals} </Td> {/* Need worker */}
        <Td>
          {isNewCaseStatus(item.caseStatus) ? (
            <Flex row justify="end">
              <Button
                href={investigationReviewPath(item.referralId)}
                variant="secondary"
              >
                Preview
              </Button>
              <Button
                href={investigationReviewPath(item.referralId)}
                variant="secondary"
              >
                Accept
              </Button>
            </Flex>
          ) : (
            <ButtonMenu
              rightAligned
              variant="secondary"
              buttonProps={{
                icon: Icons.ChevronDown,
                iconOnRight: true,
                children: "Actions",
                variant: "secondary",
              }}
              linkItems={[
                {
                  description: "View",
                  href: investigationReviewPath(item.referralId),
                },
                {
                  description: "Add Case Activity",
                  href: caseActivityNewPath(item.referralId),
                },
              ]}
            />
          )}
        </Td>
      </Tr>
    ))}
  </Tbody>
);

const HomePageCaseDashboard = ({ title, data }) => {
  console.log(data);
  return (
    <Surface
      title="Case Activity Dashboard"
      hideTitle
      className={styles.surfaceFillDashboard}
    >
      <Flex align="center" justify="space-between">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          {title}
        </Text>
        <Button variant="secondary" href={investigationsPath()}>
          View Dashboard
        </Button>
      </Flex>
      <div className={styles.overflowScroll}>
        <Table>
          <Thead>
            <Tr>
              <Th>Referral Name</Th>
              <Th>Report Timestamp</Th>
              <Th>Response</Th>
              <Th>Approvals</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <TableBody cases={data} />
        </Table>
      </div>
    </Surface>
  );
};

export default HomePageCaseDashboard;

TableBody.propTypes = {
  cases: PropTypes.array,
};

HomePageCaseDashboard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};
