import {
  Flex,
  InputDate,
  InputDropdown,
  InputFilterable,
  InputText,
  Surface,
  Text,
} from "@heart/components";
import post from "@utils/post";
import PropTypes from "prop-types";
import * as React from "react";
import { casesPath, investigationReviewPath } from "routes";

import WorkerSafetyConcern from "@components/intake_portal/Cards/WorkerSafetyConcern";
import AutoSaveStickyFooter from "@components/shared/AutoSaveStickyFooter";
import WorkerCard from "@components/shared/WorkerCard";
import workers from "@components/shared/data/workers.json";
import useFormState from "@components/shared/hooks/useFormState";
import CreateViewLayout from "@components/shared/layout/create_view_layout/CreateViewLayout";
import NewPersonForm from "@components/shared/person/NewPersonForm";

import addedWorkers from "../../utils/addedWorkers.json";
import FireworksIcon from "./FireworksIcon";
import styles from "./NewCasePage.module.scss";

const allWorkers = [
  ...workers.recommendedWorkers,
  ...workers.onCallWorkers,
  ...workers.regionalWorkers,
  ...addedWorkers.workers,
];

const sortedWorkers = allWorkers.sort((a, b) =>
  a.firstName.localeCompare(b.firstName)
);

const NewCasePage = ({ referralId, caseData, caseType }) => {
  const { formState, updateFormState } = useFormState({
    referralId,
    region: "Region 4",
    workerSafety: {
      concernType: [],
      comments: "",
    },
    referralName: "",
    caseType,
    caseStartDate: new Date().toDateString(),
    ...caseData,
  });

  const handlePersonAdd = ({ personDatabaseId, personId, personData }) => {
    updateFormState({
      ...formState,
      personDatabaseIds: [
        ...(formState.personDatabaseIds || []),
        personDatabaseId,
      ],
      personIds: [...(formState.personIds || []), personId],
      persons: [...(formState.persons || []), personData],
    });
    document.getElementById("persons")?.scrollIntoView();
  };

  return (
    <CreateViewLayout
      breadcrumb={[
        {
          href: "/",
          label: "Home",
        },
        {
          href: investigationReviewPath(referralId),
          label: referralId,
        },
        {
          href: "#",
          label: "Create New Case",
        },
      ]}
      links={[
        {
          href: "#case-overview",
          label: "Case Overview",
        },
        {
          href: "#region-and-worker-assignment",
          label: "Region and Worker Assignment",
        },
        { href: "#persons", label: "Persons" },
      ]}
      sidebarTitle="Recommended workers"
      sidebarSubtitle="AI supported recommendation of workers"
      hideSidebarTitle
      pageTitle="Create New Case"
      sidebar={
        <Flex column className={styles.workerSidebar}>
          <Flex column gap="100">
            <Flex gap="100" align="center">
              <FireworksIcon />
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Recommended Workers
              </Text>
            </Flex>
            <Text as="div" textStyle="supporting-50" textColor="neutral-600">
              AI supported recommendation of workers
            </Text>
          </Flex>
          {workers.recommendedWorkers.map(worker => (
            <WorkerCard
              key={worker.firstName + worker.lastName}
              firstName={worker.firstName}
              lastName={worker.lastName}
              address={worker.address}
              phoneNumber={worker.phoneNumber}
              pills={worker.pills}
              showEye={worker.showEye}
              isFeatured={worker.isFeatured}
            />
          ))}
          <Flex column gap="100">
            <Text textStyle="emphasis-200" textColor="neutral-600">
              On Call Workers
            </Text>
            <Text as="div" textStyle="supporting-50" textColor="neutral-600">
              Currently listed as on call during off hours. Call (403)040-3405
              for general support
            </Text>
          </Flex>
          <Flex column gap="300">
            {workers.onCallWorkers.map(worker => (
              <WorkerCard
                key={worker.firstName + worker.lastName}
                firstName={worker.firstName}
                lastName={worker.lastName}
                address={worker.address}
                phoneNumber={worker.phoneNumber}
                pills={worker.pills}
                showEye={worker.showEye}
                isFeatured={worker.isFeatured}
              />
            ))}
          </Flex>
          <Flex column gap="100">
            <Text textStyle="emphasis-200" textColor="neutral-600">
              Regional Workers
            </Text>
            <Text as="div" textStyle="supporting-50" textColor="neutral-600">
              Workers recommended based on Jursidiction selected
            </Text>
          </Flex>
          <Flex column gap="300">
            {workers.regionalWorkers.map(worker => (
              <WorkerCard
                key={worker.firstName + worker.lastName}
                firstName={worker.firstName}
                lastName={worker.lastName}
                address={worker.address}
                phoneNumber={worker.phoneNumber}
                pills={worker.pills}
                showEye={worker.showEye}
                isFeatured={worker.isFeatured}
              />
            ))}
          </Flex>
        </Flex>
      }
      footer={
        <AutoSaveStickyFooter
          referralId={referralId}
          cancelText="Cancel"
          submitText="Save and Create Case"
          text="Case ID: 123045856"
          formState={formState}
          onSubmit={async () => {
            await post(casesPath(), formState);
          }}
        />
      }
    >
      {({ sectionRefs, handleDivClick }) => (
        <React.Fragment>
          <div
            id="case-overview"
            ref={el => (sectionRefs.current["case-overview"] = el)}
            onClick={() => handleDivClick("case-overview")}
          >
            <Surface title="Case Overview" hideTitle>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Case Overview
              </Text>
              <Flex column gap="300">
                <InputText
                  label="Case Name"
                  value={formState.referralName}
                  onChange={v => updateFormState({ referralName: v })}
                  required
                />
                <InputDropdown
                  id="case-type"
                  label="Case Type"
                  values={[
                    {
                      label: "Out-of-Home: Foster Care",
                      value: "foster_care",
                    },
                    { label: "In-Home: Prevention", value: "prevention" },
                  ]}
                  value={formState.caseType}
                  onChange={v => updateFormState({ caseType: v })}
                  required
                />
                <InputDate
                  label="Case Start Date"
                  value={formState.caseStartDate}
                  onChange={v => updateFormState({ caseStartDate: v })}
                  required
                />
              </Flex>
            </Surface>
          </div>
          <div
            id="region-and-worker-assignment"
            ref={el =>
              (sectionRefs.current["region-and-worker-assignment"] = el)
            }
            onClick={() => handleDivClick("region-and-worker-assignment")}
          >
            <Surface title="Region and Worker Assignments" hideTitle>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Region and Worker Assignments
              </Text>
              <Flex className={styles.inputWidth}>
                <InputDropdown
                  id="region"
                  label="Region"
                  value={formState.region}
                  onChange={v => updateFormState({ region: v })}
                  values={[
                    "Region 1",
                    "Region 2",
                    "Region 3",
                    "Region 4",
                    "Region 5",
                    "Region 6",
                  ]}
                />
              </Flex>

              <InputFilterable
                id="primaryWorkerAssigned"
                label="Primary Worker Assigned"
                name="primaryWorkerAssigned"
                value={formState.workerAssigned}
                values={sortedWorkers.map(worker => ({
                  label: `${worker.firstName} ${worker.lastName}`,
                  value: `${worker.firstName} ${worker.lastName}`,
                }))}
                onChange={workerAssigned => updateFormState({ workerAssigned })}
              />
              <InputFilterable
                id="secondaryWorkerAssigned"
                label="Secondary Worker Assigned"
                name="secondaryWorkerAssigned"
                values={[]}
              />
              <InputFilterable
                id="supervisorAssigned"
                label="Supervisor Assigned"
                name="supervisorAssigned"
                values={[]}
              />
            </Surface>
          </div>
          <div
            id="worker-safety-concerns"
            ref={el => (sectionRefs.current["worker-safety-concerns"] = el)}
            onClick={() => handleDivClick("worker-safety-concerns")}
          >
            <WorkerSafetyConcern
              formState={formState}
              updateFormState={updateFormState}
            />
          </div>
          <div
            id="persons"
            ref={el => (sectionRefs.current["persons"] = el)}
            onClick={() => handleDivClick("persons")}
          >
            <Surface title="Case Overview" hideTitle>
              <Text textStyle="emphasis-200" textColor="neutral-600">
                Persons
              </Text>
              <NewPersonForm
                onPersonAdd={handlePersonAdd}
                formState={formState}
                buttonClass={styles.newPersonButton}
                updateWindowsLocation={"#persons"}
              />
            </Surface>
          </div>
        </React.Fragment>
      )}
    </CreateViewLayout>
  );
};

NewCasePage.propTypes = {
  referralId: PropTypes.string.isRequired,
  caseData: PropTypes.object.isRequired,
  caseType: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
};

export default NewCasePage;
