import { Button, Flex } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";
import * as React from "react";

import ReviewLayout from "@components/shared/layout/review_layout/ReviewLayout";

import IntakeSubmissionModal from "../IntakeSubmissionModal";
import IntakeReport from "./IntakeReport";
import ReviewRecommendation from "./ReviewRecommendation";

const IntakeReviewPage = ({
  currentUser,
  referralId,
  caseData,
  caseActivity,
  incidentTimestamp = "2023-07-24T22:30:30.000Z",
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [formState, setFormState] = useState({
    /** Default, should be overwritten by caseData */
    attachments: [
      "LBrown-visitsummary.pdf",
      "IMG_0405.jpg",
      "03950-001 Portal Reported Export.pdf",
    ],
    /** Default, should be overwritten by caseData */
    persons: [
      {
        role: ["victim"],
        name: "Liam",
        middleName: "",
        lastName: "Brown",
        suffix: "",
        isNameUnknown: false,
        dob: "2019-04-19",
        isDobEstimate: false,
        phoneNumber: "",
        email: "",
        sex: "male",
        isVictimInSchool: "Yes",
        tribalAffiliation: "Unknown",
        physicalDescriptor: "3'7\" 45lb Dark blonde hair missing 1 front tooth",
        personIsDeceased: false,
      },
      {
        role: ["caretaker", "alleged-abuser"],
        name: "Rachel",
        middleName: "",
        lastName: "Brown",
        suffix: "",
        isNameUnknown: false,
        dob: "1991-10-13",
        isDobEstimate: false,
        phoneNumber: "(304)304-3688",
        email: "",
        sex: "female",
        isVictimInSchool: "No",
        tribalAffiliation: "Unknown",
        physicalDescriptor:
          "5'1\" 130lbs dark hair pulled back sunspots on face",
        personIsDeceased: false,
      },
    ],
    relations: [],
    allegations: [],
    screeningToolResult: null,
    intakeRecommendation: {
      acceptIntakeResponse: true,
      reasoning: "",
      recommendedResponse: "",
      recommendedResponseLevel: null,
    },
    incidentTimestamp,
    ...caseData,
    incidentInfo: {
      referralId,
      ...caseData?.incidentInfo,
    },
  });

  const updateFormState = newState => {
    setFormState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  const handlePersonAdd = ({ personDatabaseId, personId, personData }) => {
    updateFormState({
      personDatabaseIds: [
        ...(formState.personDatabaseIds || []),
        personDatabaseId,
      ],
      personIds: [...(formState.personIds || []), personId],
      persons: [...(formState.persons || []), personData],
    });
    document.getElementById("persons")?.scrollIntoView();
  };

  return (
    <React.Fragment>
      <ReviewLayout
        pill={{ text: "In Process" }}
        title={`Referral ${referralId}`}
        breadcrumb={[
          {
            href: "/",
            label: "Home",
          },
          {
            href: "/intake",
            label: "Maltreatment Reports",
          },
          {
            href: "#",
            label: `Referral ${referralId}`,
          },
        ]}
        tabs={[
          {
            contents: (
              <IntakeReport
                referralId={referralId}
                caseActivity={caseActivity}
                formState={formState}
                handlePersonAdd={handlePersonAdd}
                updateFormState={updateFormState}
              />
            ),
            id: "intake-report",
            title: "Intake Report",
          },
          {
            contents: (
              <ReviewRecommendation
                formState={formState}
                updateFormState={updateFormState}
                setOpenModal={setOpenModal}
              />
            ),
            id: "review-and-recommendation",
            title: "Review & Recommendation",
          },
        ]}
        headerRightAlignedContent={
          <Flex gap="100">
            <Button variant="secondary">Export as PDF</Button>
          </Flex>
        }
      />
      <IntakeSubmissionModal
        currentUser={currentUser}
        open={openModal}
        formState={formState}
        referralId={referralId}
        onCancel={() => setOpenModal(false)}
        intakeRecommendation={
          formState.intakeRecommendation.acceptIntakeResponse
        }
        recommendationReasoning={formState.intakeRecommendation.reasoning ?? ""}
        response={{
          label: formState.intakeRecommendation.acceptIntakeResponse
            ? formState.screeningToolResult?.label ?? ""
            : formState.intakeRecommendation.recommendedResponse,
          name: formState.intakeRecommendation.acceptIntakeResponse
            ? formState.screeningToolResult?.name ?? ""
            : formState.intakeRecommendation.recommendedResponseLevel,
        }}
      />
    </React.Fragment>
  );
};

IntakeReviewPage.propTypes = {
  referralId: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  caseData: PropTypes.object,
  caseActivity: PropTypes.array,
  incidentTimestamp: PropTypes.string,
};

export default IntakeReviewPage;
