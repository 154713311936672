/* eslint-disable react/prop-types */
import { Flex, Link, Pill, Surface, Text } from "@heart/components";
import React from "react";

import ButtonIcon from "@components/shared/ButtonIcon";
import CheckIcon from "@components/shared/assets/check";
import EyeIcon from "@components/shared/assets/eye";
import IconPlus from "@components/shared/assets/icon_plus";
import PencilIcon from "@components/shared/assets/pencil";

import styles from "../HomePage.module.scss";

// Dates appear in a pill; dates overdue are orange; 0-10 days are yellow; 11+ days are blue.
// Warning = Orange = Negative
// Alert = Yellow = 0-10 days
// Info = Blue = 11+ days

const HomePageTodos = ({ todos = [], setOpenTodos }) => {
  const TitleButtons = () => (
    <Flex>
      <ButtonIcon svg={IconPlus} onClick={() => setOpenTodos(true)} />
      <ButtonIcon svg={EyeIcon} onClick={() => {}} />
    </Flex>
  );

  const CaseRowItem = ({ children }) => (
    <Flex
      row
      align="center"
      justify="space-between"
      gap="100"
      className={styles.caseRowItem}
    >
      {children}
    </Flex>
  );

  return (
    <Surface
      title="Todos"
      className={styles.surfaceFillDashboard}
      bonusContent={<TitleButtons />}
    >
      <Flex column className={styles.todoFlexColumn}>
        {todos.map(todo => (
          <CaseRowItem key={todo.ids}>
            <Flex row justify="start" align="center" gap="100">
              <Pill text={todo.pillDate} variant={todo.variant} />
              <Flex row wrap justify="start" align="center">
                <Text textStyle="body-100" textColor="neutral-600">
                  {todo.assessmentType}:
                </Text>
                <Link> {todo.ids} </Link>
              </Flex>
            </Flex>
            <Flex row gap="0">
              <ButtonIcon svg={PencilIcon} onClick={() => {}} />
              <ButtonIcon svg={CheckIcon} onClick={() => {}} />
            </Flex>
          </CaseRowItem>
        ))}
        <Flex row justify="start">
          <Link textStyle="body-100" textColor="neutral-600">
            See all {Math.floor(Math.random() * (32 - 8)) + 7} Todos
          </Link>
        </Flex>
      </Flex>
    </Surface>
  );
};

export default HomePageTodos;
