import {
  Modal,
  Flex,
  InputText,
  InputTextarea,
  InputDate,
  InputFilterable,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

import styles from "../HomePage.module.scss";

const TodosModal = ({ open = false, onCancel }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [associatedCase, setAssociatedCase] = useState("");
  const [inReferenceTo, setInReferenceTo] = useState("");

  const handleSave = () => {
    closeForm();
  };

  const closeForm = () => {
    setName("");
    setDescription("");
    setDueDate("");
    setAssociatedCase("");
    setInReferenceTo("");

    onCancel?.();
  };

  return (
    <Modal
      hidden={!open}
      title="Add New Todo"
      onCancel={closeForm}
      onSubmit={handleSave} // handleSave later
      cancelText="Cancel"
      submitText="Save"
      className={styles.modalTodo}
      blendActionBackground
    >
      <Flex column gap="300">
        <InputText label="Todo Name" onChange={setName} value={name} required />
        <InputTextarea
          label="Description"
          onChange={setDescription}
          value={description}
        />
        <InputDate
          label="Due Date"
          onChange={setDueDate}
          value={dueDate}
          required
        />
        <InputFilterable
          required
          values={[]}
          label="Associated Case"
          onChange={() => {}}
        />
        <InputFilterable
          required
          values={[]}
          label="In Reference To"
          onChange={() => {}}
        />
      </Flex>
    </Modal>
  );
};

TodosModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default TodosModal;
