import { Surface, Text, Flex, Button } from "@heart/components";

import ProviderInformationTable from "@components/shared/ProviderInformationTable";

import { usePersonInformation } from "../PersonContext";

const Mental = () => {
  const { formState } = usePersonInformation();
  const providers = formState.providers || [];

  // Filter for mental health providers
  const mentalProviders = providers.filter(provider => {
    const { providerType } = provider;
    return (
      providerType?.includes("mental") ||
      providerType === "therapist-psychologist" ||
      providerType === "psychiatrist"
    );
  });

  return (
    <Surface title="Behavioral" hideTitle sectionId="behavioral">
      <Flex column gap="200" align="start">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Mental/Behavioral Health
        </Text>
        {mentalProviders.length > 0 ? (
          <ProviderInformationTable providers={mentalProviders} />
        ) : null}
        <Button>Add Provider</Button>
      </Flex>
    </Surface>
  );
};

export default Mental;
