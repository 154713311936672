import {
  Breadcrumbs,
  Button,
  ContentTabs,
  Flex,
  Icons,
  Text,
} from "@heart/components";
import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import PropTypes from "prop-types";
import * as React from "react";

import CaseActivity from "./CaseActivity";
import { PersonProvider } from "./PersonContext";
import styles from "./PersonProfilePage.module.scss";
import PersonalInformation from "./PersonalInformation";
import QuickContact from "./QuickContact";
import WellBeing from "./WellBeing";
import RelationshipsDashboard from "./relationships/RelationshipsDashboard";

const PersonProfilePage = ({
  localDev,
  person,
  caseActivity,
  diagnoses,
  prescriptions,
  providers,
  visits,
  medicalInsurances,
  addPersonFormPath,
  downloadRelationshipsCsvPath,
  editContactLogBasePath,
  downloadContactLogsCsvPath,
}) => (
  <PersonProvider
    initialData={{
      person,
      diagnoses,
      prescriptions,
      providers,
      visits,
      medicalInsurances,
    }}
  >
    {({ formState }) => (
      <React.Fragment>
        {console.log(diagnoses, prescriptions, providers)}
        {console.log("visits", visits)}
        {console.log("medicalInsurances", medicalInsurances)}
        <div className={styles.header}>
          <Flex column gap="300">
            <Breadcrumbs
              pages={[
                {
                  href: "/",
                  label: "Home",
                },
                {
                  href: "/children-and-youth",
                  label: "Children + Youth",
                },
                {
                  href: "#",
                  label: `${formState.person.firstName} ${formState.person.lastName} (${formState.person.id})`,
                },
              ]}
            />
            <ResolutionOnly medium over>
              <Flex justify="space-between" align="center">
                <Text
                  textStyle="emphasis-200"
                  textColor="neutral-600"
                >{`${formState.person.firstName} ${formState.person.lastName} (${formState.person.id})`}</Text>
                <Flex gap="100">
                  <Button variant="secondary">Export as PDF</Button>
                  <Button icon={Icons.Plus}>Create Case Activity</Button>
                </Flex>
              </Flex>
            </ResolutionOnly>
            <Flex>
              <Flex gap="200">
                <img
                  src="/assets/ccwis/liam.png"
                  className={styles.profileImage}
                  alt="Profile"
                />
                <Flex column>
                  <ResolutionOnly medium under>
                    <Text
                      textStyle="emphasis-200"
                      textColor="neutral-600"
                    >{`${formState.person.firstName} ${formState.person.lastName} (${formState.person.id})`}</Text>
                  </ResolutionOnly>
                  <Flex align="center">
                    <Icons.Calendar />
                    <Text textStyle="body-100" textColor="neutral-600">
                      {formState.person.dob} (age {formState.person.age})
                    </Text>
                  </Flex>
                  <Text textStyle="body-100" textColor="neutral-600">
                    {formState.person.sex}
                  </Text>
                </Flex>
              </Flex>
              <ResolutionOnly medium over>
                <QuickContact
                  phone={formState.person.phone}
                  address={formState.person.address}
                />
              </ResolutionOnly>
            </Flex>
            <ResolutionOnly medium under>
              <Flex gap="100">
                <Button variant="secondary" fullWidth>
                  Export as PDF
                </Button>
                <Button icon={Icons.Plus} fullWidth>
                  Create Case Activity
                </Button>
              </Flex>
            </ResolutionOnly>
            <ResolutionOnly medium under>
              <QuickContact
                phone={formState.person.phone}
                address={formState.person.address}
              />
            </ResolutionOnly>
          </Flex>
        </div>
        <Flex column className={styles.tabs}>
          <ContentTabs
            param="topLevelTab"
            tabs={[
              {
                contents: <div>Summary</div>,
                id: "summary",
                title: "Summary",
              },
              {
                contents: <PersonalInformation />,
                id: "personal-information",
                title: "Personal Information",
              },
              {
                contents: <WellBeing />,
                id: "well-being",
                title: "Well Being",
              },
              {
                contents: (
                  <RelationshipsDashboard
                    childAgencyHumanId={localDev ? "1336" : "11841"}
                    addPersonFormPath={addPersonFormPath}
                    downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
                    editContactLogBasePath={editContactLogBasePath}
                    downloadContactLogsCsvPath={downloadContactLogsCsvPath}
                  />
                ),
                id: "relationships",
                title: "Relationships",
              },
              {
                contents: <div>Case History</div>,
                id: "case-history",
                title: "Case History",
              },
              {
                contents: <div>Audit Log</div>,
                id: "audit-log",
                title: "Audit Log",
              },
              {
                contents: <CaseActivity caseActivity={caseActivity} />,
                id: "case-activity",
                title: "Case Activity",
              },
            ]}
          />
        </Flex>
      </React.Fragment>
    )}
  </PersonProvider>
);

PersonProfilePage.propTypes = {
  localDev: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  person: PropTypes.object.isRequired,
  caseActivity: PropTypes.array.isRequired,
  diagnoses: PropTypes.array.isRequired,
  prescriptions: PropTypes.array.isRequired,
  visits: PropTypes.array.isRequired,
  medicalInsurances: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  addPersonFormPath: PropTypes.string,
  downloadRelationshipsCsvPath: PropTypes.string,
  editContactLogBasePath: PropTypes.string,
  downloadContactLogsCsvPath: PropTypes.string,
};

export default PersonProfilePage;
