import PropTypes from "prop-types";

import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

import RelationshipsTable from "./RelationshipsTable";
import Genogram from "./WIPGenogram";

/** Component to toggle between displaying relationship information as a
 * table or as a genogram
 */
const RelationshipsTableOrGenogram = ({
  childAgencyHumanId,
  addPersonFormPath,
  downloadRelationshipsCsvPath,
}) => {
  const view = useBase64SearchParam("view");

  if (view === "genogram") {
    return <Genogram childAgencyHumanId={childAgencyHumanId} />;
  }

  return (
    <RelationshipsTable
      tab="relationships"
      familyFindingEnabled={true}
      addPersonFormPath={addPersonFormPath}
      keystoneAgencyHumanId={childAgencyHumanId}
      downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
    />
  );
};
RelationshipsTableOrGenogram.propTypes = {
  /** Genogram is only available for agencies with family finding enabled,
   * the relationships table is available to agencies with prevention as well
   */
  familyFindingEnabled: PropTypes.bool,
  addPersonFormPath: PropTypes.string.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  downloadRelationshipsCsvPath: PropTypes.string.isRequired,
  ffAddPotentialConnections012024: PropTypes.bool,
};

export default RelationshipsTableOrGenogram;
