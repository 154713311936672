import { HeartTable, LabeledContent, TableCellList } from "@heart/components";
import formatAddress from "@utils/address";
import PROVIDER_TYPES_LABEL from "@utils/providerTypes";
import PropTypes from "prop-types";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const ProviderInformationTable = ({ providers }) => (
  <Table>
    <Thead>
      <Tr>
        <Th>Provider Information</Th>
        <Th>Patient Information</Th>
        <Th>Associated Documentation</Th>
      </Tr>
    </Thead>
    <Tbody>
      {providers.map((provider, i) => (
        <Tr key={`provider-${i}`}>
          <Td>
            <TableCellList
              items={[
                <LabeledContent
                  key="type"
                  label="Provider Type"
                  content={
                    provider.providerType
                      ? PROVIDER_TYPES_LABEL[provider.providerType]
                      : " "
                  }
                />,
                <LabeledContent
                  key="name"
                  label="Name"
                  content={provider.name || " "}
                />,
                <LabeledContent
                  key="address"
                  label="Address"
                  content={
                    provider.address ? formatAddress(provider.address) : " "
                  }
                />,
                <LabeledContent
                  key="email"
                  label="Email"
                  content={provider.email || " "}
                />,
                <LabeledContent
                  key="phone"
                  label="Phone Number"
                  content={provider.phoneNumber || " "}
                />,
              ]}
            />
          </Td>
          <Td>
            <TableCellList
              items={[
                <LabeledContent
                  key="patientId"
                  label="Patient ID Number"
                  content=" "
                />,
                <LabeledContent
                  key="portalLink"
                  label="Link to Portal"
                  content=" "
                />,
                <LabeledContent
                  key="portalUsername"
                  label="Portal Username"
                  content=" "
                />,
                <LabeledContent
                  key="portalPassword"
                  label="Portal Password"
                  content=" "
                />,
              ]}
            />
          </Td>
          <Td alignRight></Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

ProviderInformationTable.propTypes = {
  providers: PropTypes.array.isRequired,
};

export default ProviderInformationTable;
