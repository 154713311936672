export const links = [
  {
    href: "#",
    text: "Washington DCYF Policy and Practice guide",
  },
  {
    href: "https://www.dcyf.wa.gov/practice/practice-improvement",
    text: "DCYF Practice Improvement",
  },
  {
    href: "https://www.dcyf.wa.gov/tribal-relations/icw",
    text: "DCYF Indian Child Welfare Resources",
  },
  {
    href: "https://risewiththealliance.org/programs/development-for-professionals/development-for-professionals-social-work/development-for-professionals-social-work-mandatory-trainings/",
    text: "The Alliance",
  },
  {
    href: "https://findhelp.org",
    text: "FindHelp.org",
  },
];

const generatePillDate = offset => {
  const today = new Date();
  const newDate = new Date(today);
  newDate.setDate(today.getDate() + offset);
  const formattedDate = newDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};

// Dates appear in a pill; dates overdue are orange; 0-10 days are yellow; 11+ days are blue.
// Warning = Orange = Negative
// Alert = Yellow = 0-10 days
// Info = Blue = 11+ days

export const initialTodos = [
  {
    variant: "warning",
    assessmentType: "Findings",
    ids: "INV-56908",
    pillDate: generatePillDate(-2),
  },
  {
    variant: "alert",
    assessmentType: "Safety Assessment",
    ids: "INV-57046",
    pillDate: generatePillDate(4),
  },
  {
    variant: "alert",
    assessmentType: "Findings",
    ids: "INV-57164",
    pillDate: generatePillDate(5),
  },
  {
    variant: "info",
    assessmentType: "Investigation Closure",
    ids: "INV-57287",
    pillDate: generatePillDate(15),
  },
  {
    variant: "info",
    assessmentType: "Risk Assessment",
    ids: "INV-57379",
    pillDate: generatePillDate(20),
  },
];
