import { Surface, Text, Flex, Button } from "@heart/components";

import ProviderInformationTable from "@components/shared/ProviderInformationTable";

import { usePersonInformation } from "../PersonContext";

const Dental = () => {
  const { formState } = usePersonInformation();
  const providers = formState.providers || [];

  // Filter for dental providers
  const dentalProviders = providers.filter(provider => {
    const { providerType } = provider;
    return providerType === "dentist";
  });

  return (
    <Surface title="Dental" hideTitle sectionId="dental">
      <Flex column gap="200" align="start">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Dental
        </Text>
        {dentalProviders.length > 0 ? (
          <ProviderInformationTable providers={dentalProviders} />
        ) : null}
        <Button>Add Provider</Button>
      </Flex>
    </Surface>
  );
};

export default Dental;
