import {
  Surface,
  Flex,
  Text,
  HeartTable,
  TableCellList,
  LabeledContent,
  Icons,
  Button,
} from "@heart/components";

import { usePersonInformation } from "../PersonContext";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const MedicalInsuranceInformation = () => {
  const { formState } = usePersonInformation();

  return (
    <Surface
      title="Medical Insurance Information"
      hideTitle
      sectionId="medicalInsuranceInformation"
    >
      <Flex column gap="200" align="start">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Medical Insurance Information
        </Text>
        {formState.medicalInsurances?.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>Policy Information & Coverage Type</Th>
                <Th>Dates</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {formState.medicalInsurances.map(medicalInsurance => (
                <Tr key={medicalInsurance.policyNumber}>
                  <Td>
                    <TableCellList
                      items={[
                        <LabeledContent
                          key="policyNumber"
                          label="Policy Number"
                          content={medicalInsurance.policyNumber}
                        />,
                        <LabeledContent
                          key="policyHolder"
                          label="Policy Holder"
                          content={medicalInsurance.policyHolder}
                        />,
                        <LabeledContent
                          key="coverageType"
                          label="Coverage Type"
                          content={medicalInsurance.coverageType}
                        />,
                      ]}
                    />
                  </Td>
                  <Td>
                    {medicalInsurance.dateStart} -{" "}
                    {medicalInsurance.dateEnd || "Present"}
                  </Td>
                  <Td alignRight>
                    <Flex justify="end">
                      <Icons.Edit onClick={() => {}} />
                      <Icons.Trash onClick={() => {}} />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : null}
        <Button>Add Insurance Policy</Button>
      </Flex>
    </Surface>
  );
};

export default MedicalInsuranceInformation;
