const formatAddress = address => {
  if (!address) return "";
  if (typeof address === "string") return address;

  const { addressLine1, city, primarySubdivisionCode, postalCode } = address;
  const components = [addressLine1, city, primarySubdivisionCode, postalCode];

  return components.filter(Boolean).join(", ");
};

export default formatAddress;
