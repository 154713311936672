import {
  Surface,
  Text,
  Flex,
  Avatar,
  DetailsTable,
  Pill,
  Icons,
  Link,
} from "@heart/components";
import {
  displaySelectedRaces,
  displaySelectedEthnicities,
} from "@utils/ethnicitiesAndRaces";
import { capitalize } from "lodash";
import PropTypes from "prop-types";

import { formatAsLongDate } from "@lib/dates";

import styles from "./PersonCard.module.scss";

const roles = {
  victim: "Victim",
  "alleged-abuser": "Abuser",
  caretaker: "Caretaker",
  "other-child-in-the-home": "Child in the Home",
  "other-adult-in-the-home": "Adult in the Home",
  "other-person-not-in-the-home": "Person not in the Home",
};

const getAge = dateString => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

const formatAddress = address => {
  if (!address) return "";

  const { addressLine1, city, primarySubdivisionCode, postalCode } = address;
  const components = [addressLine1, city, primarySubdivisionCode, postalCode];

  return components.filter(Boolean).join(", ");
};

const PersonCard = ({ person, hasNotice, askMilitaryPersonnelInfo }) => {
  const age = `(${person.isDobEstimate ? "Estimated " : ""}${getAge(
    person.dob
  )} years
  old)`;

  const firstColumnDetails = [
    {
      label: "DOB",
      value: formatAsLongDate(person.dob),
    },
    {
      label: "Role",
      value: (
        <Flex gap="100" wrap>
          {(person.role || []).map(r => (
            <Pill key={person.name + r} text={roles[r] || r} />
          ))}
        </Flex>
      ),
    },
    {
      label: "Sex",
      value: capitalize(person.sex) || "Unknown",
    },
    askMilitaryPersonnelInfo && {
      label: "Military personnel?",
      value: person.militaryPersonnel,
    },
    {
      label: "Member of a tribe?",
      value: `${person.tribalAffiliation}. ${person.tribeBandName || ""}`,
    },
    person.tribalIdentification
      ? {
          label: "Tribal Identification",
          value: person.tribalIdentification,
        }
      : {},
  ].filter(Boolean);

  const secondColumnDetails = [
    {
      label: "Phone #",
      value: person.phoneNumber,
    },
    {
      label: "Race",
      value: person.race ? displaySelectedRaces(person.race) : null,
    },
    {
      label: "Ethnicity",
      value: person.ethnicity
        ? displaySelectedEthnicities(person.ethnicity)
        : null,
    },
    {
      label: "Address",
      value: person.isAddressUnknown
        ? "Unknown"
        : formatAddress(person.address),
    },
    {
      label: "Physical Description",
      value: person.physicalDescriptor || "Unknown",
    },
  ];

  if ((person.role || []).includes("victim")) {
    firstColumnDetails.push({
      label: "Enrolled in School?",
      value:
        person.isVictimInSchool === "Yes" && person.school
          ? `${person.isVictimInSchool}. ${person.school}`
          : person.isVictimInSchool,
    });
  }

  return (
    <Surface hideTitle title={person.name} sh>
      <Flex gap="200" column>
        <Flex justify="space-between">
          <Text textStyle="emphasis-200" textColor="neutral-600">
            {person.name} {person.lastName} {age}
          </Text>

          <Flex gap="100" row justify="space-between" align="center">
            <Icons.Edit onClick={() => {}} />
            <Icons.Trash onClick={() => {}} />
          </Flex>
        </Flex>
        {hasNotice && person.name.toLowerCase().includes("liam") && (
          <Surface gap="100" className={styles.notice} hideTitle>
            <Text textColor="neutral-600" textStyle="emphasis-200">
              Possible Duplicates
            </Text>
            <Flex gap="200" column>
              <Flex row gap="50">
                <Link>Liam Brown (394239)</Link>,
                <Link>Liam Lopez (348594)</Link>,
                <Link>Lorelei Brown (395903)</Link>
              </Flex>
              <Flex row gap="50">
                <Text textColor="neutral-600">or </Text>{" "}
                <Link> Launch Advanced Search</Link>
              </Flex>
            </Flex>
          </Surface>
        )}
        <Flex>
          <Flex grow>
            <Avatar
              firstName={person.name}
              lastName={person.lastName}
              size={800}
            />
          </Flex>
          <Flex>
            <Flex className={styles.infoColumn}>
              <DetailsTable details={firstColumnDetails} />
            </Flex>
            <Flex className={styles.infoColumn}>
              <DetailsTable details={secondColumnDetails} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Surface>
  );
};

PersonCard.propTypes = {
  person: PropTypes.shape({
    role: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    isNameUnknown: PropTypes.bool,
    dob: PropTypes.string,
    isDobEstimate: PropTypes.bool,
    address: PropTypes.shape({
      addressLine1: PropTypes.string,
      city: PropTypes.string,
      primarySubdivisionCode: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    phoneNumber: PropTypes.string,
    race: PropTypes.string,
    ethnicity: PropTypes.string,
    sex: PropTypes.oneOf(["male", "female", "intersex", "unknown"]),
    isVictimInSchool: PropTypes.oneOf(["Yes", "No", "I'm not sure"]),
    school: PropTypes.string,
    tribalAffiliation: PropTypes.oneOf(["Yes", "No", "Unknown"]),
    tribeBandName: PropTypes.string,
    tribalIdentification: PropTypes.string,
    militaryPersonnel: PropTypes.oneOf(["Yes", "No", "Unknown"]),
    physicalDescriptor: PropTypes.string,
    personIsDeceased: PropTypes.bool,
    isAddressUnknown: PropTypes.bool,
  }).isRequired,
  hasNotice: PropTypes.bool,
  askMilitaryPersonnelInfo: PropTypes.bool,
};

export default PersonCard;
