import { Button, Flex, Icons, Link, Surface, Text } from "@heart/components";
import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import PropTypes from "prop-types";
import { useState } from "react";
import { caseActivityNewPath } from "routes";

import styles from "./HomePage.module.scss";
import CaseHealthMetric from "./components/CaseHealthMetric";
import HomePageCaseDashboard from "./components/HomePageCaseDashboard";
import HomePageCaseManagement from "./components/HomePageCaseManagement";
import HomePageCaseMobile from "./components/HomePageCaseMobile";
import HomePageCaseSupervisorDashboard from "./components/HomePageCaseSupervisorDashboard";
import HomePageResources from "./components/HomePageResources";
import HomePageTodos from "./components/HomePageTodos";
import MetricTile from "./components/MetricTile";
import MonthlyVisits from "./components/MonthlyVisit";
import ResourceModal from "./components/ResourceModal";
import RiskAssessments from "./components/RiskAssessments";
import SafetyAssesments from "./components/SafetyAssesments";
import TodosModal from "./components/TodosModal";
import { initialTodos, links } from "./mockData";

const HomePage = ({ currentUser, cases }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openTodos, setOpenTodos] = useState(false);
  const [resources, setResources] = useState(links);
  const [todos, setTodos] = useState(initialTodos);

  console.log(cases);

  if (!currentUser.slug) {
    return <Text>Not logged in or slug is missing..</Text>;
  }

  return (
    <div className={styles.container}>
      <Flex
        row
        mobileColumn
        justify="space-between"
        align="center"
        className={styles.titleHeader}
      >
        <Text textStyle="emphasis-300"> Welcome, {currentUser.firstName}!</Text>
        <Flex mobileColumn>
          <Button variant="secondary">Edit My Homepage</Button>
          <Button icon={Icons.Plus} href={caseActivityNewPath()}>
            New Case Activity
          </Button>
        </Flex>
      </Flex>
      {displayDashboard({
        slug: currentUser.slug,
        setOpenModal,
        resources,
        todos,
        setOpenTodos,
        data: cases,
      })}
      <ResourceModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        setResources={setResources}
      />
      <TodosModal
        open={openTodos}
        onCancel={() => setOpenTodos(false)}
        setTodos={setTodos}
      />
    </div>
  );
};

HomePage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  cases: PropTypes.array,
};

export default HomePage;

const displayDashboard = ({
  slug,
  setOpenModal,
  resources,
  todos,
  setOpenTodos,
  data,
}) => {
  const daysUntil = (day, month, year) => {
    const targetDate = new Date(year, month - 1, day); // month is 0-indexed
    const currentDate = new Date();

    const timeDifference = targetDate - currentDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };
  switch (slug) {
    case "jackson":
      return (
        <div className={styles.dashboardGrid}>
          <div className={styles.dashboardGrid__referrals}>
            <ResolutionOnly small over>
              <HomePageCaseDashboard
                data={data}
                title={"Recently Viewed Referrals"}
              />
            </ResolutionOnly>
            <ResolutionOnly small under>
              <HomePageCaseMobile
                data={data}
                title={"Recently Viewed Referrals"}
                investigations
              />
            </ResolutionOnly>
          </div>
          <div className={styles.dashboardGrid__todos}>
            <HomePageTodos todos={todos} setOpenTodos={setOpenTodos} />
          </div>
          <div className={styles.dashboardGrid__metrics}>
            <Surface
              title="Key Metrics & Insights"
              className={styles.surfaceFill}
            >
              <Flex row gap="300" wrap>
                <MetricTile
                  numericString="71%"
                  color="green"
                  link="Referrals completed within response time-frames"
                />
                <MetricTile
                  numericString="8"
                  link="Average days to Safety Plan Completion"
                />
                <MetricTile
                  numericString="37"
                  color="green"
                  link="Average Days for Investigation Completion"
                />
              </Flex>
            </Surface>
          </div>
          <div className={styles.dashboardGrid__safetyAssessments}>
            <Surface
              title="Outcome of Safety Assessments"
              className={styles.surfaceFill}
              bonusContent={<Icons.ExternalLink onClick={() => {}} />}
            >
              <img src={SafetyAssesments} alt="safety"></img>
            </Surface>
          </div>
          <div className={styles.dashboardGrid__resources}>
            <HomePageResources
              resources={resources}
              setOpenModal={setOpenModal}
            />
          </div>
          <div className={styles.dashboardGrid__riskAssessments}>
            <Surface
              title="Outcome of Risk Assessments"
              className={styles.surfaceFill}
              bonusContent={<Icons.ExternalLink onClick={() => {}} />}
            >
              <img src={RiskAssessments} alt="risk"></img>
            </Surface>
          </div>
        </div>
      );
    case "grant":
      return (
        <div className={styles.dashboardGrid}>
          <div className={styles.dashboardGrid__referrals}>
            <ResolutionOnly small over>
              <HomePageCaseSupervisorDashboard
                data={data}
                title={"Recently Viewed Referrals"}
              />
            </ResolutionOnly>
            <ResolutionOnly small under>
              <HomePageCaseMobile
                data={data}
                title={"Recently Viewed Referrals"}
                investigations
              />
            </ResolutionOnly>
          </div>
          <div className={styles.dashboardGrid__todos}>
            <HomePageTodos todos={todos} setOpenTodos={setOpenTodos} />
          </div>
          <div className={styles.dashboardGrid__metrics}>
            <Surface
              title="Key Metrics & Insights"
              className={styles.surfaceFill}
            >
              <Flex row gap="300" wrap>
                <MetricTile
                  numericString="75%"
                  color="green"
                  link="Referrals completed within response time-frames"
                />
                <MetricTile
                  numericString="7"
                  link="Average days to Safety Plan Completion"
                />
                <MetricTile
                  numericString="45"
                  color="green"
                  link="Average Days for Investigation Completion"
                />
              </Flex>
            </Surface>
          </div>
          <div className={styles.dashboardGrid__safetyAssessments}>
            <Surface
              title="Outcome of Safety Assessments"
              className={styles.surfaceFill}
              bonusContent={<Icons.ExternalLink onClick={() => {}} />}
            >
              <img src={SafetyAssesments} alt="safety"></img>
            </Surface>
          </div>
          <div className={styles.dashboardGrid__resources}>
            <HomePageResources
              resources={resources}
              setOpenModal={setOpenModal}
            />
          </div>
          <div className={styles.dashboardGrid__riskAssessments}>
            <Surface
              title="Outcome of Risk Assessments"
              className={styles.surfaceFill}
              bonusContent={<Icons.ExternalLink onClick={() => {}} />}
            >
              <img src={RiskAssessments} alt="risk"></img>
            </Surface>
          </div>
        </div>
      );
    case "fatima":
      return (
        <div className={styles.dashboardGrid}>
          <div className={styles.dashboardGrid__referrals}>
            <ResolutionOnly small over>
              <HomePageCaseManagement
                data={data}
                title={"Recently Viewed Cases"}
              />
            </ResolutionOnly>
            <ResolutionOnly small under>
              <HomePageCaseMobile
                data={data}
                title={"Recently Viewed Cases"}
                investigation={false}
              />
            </ResolutionOnly>
          </div>
          <div className={styles.dashboardGrid__todos}>
            <HomePageTodos todos={todos} setOpenTodos={setOpenTodos} />
          </div>
          <div className={styles.dashboardGrid__metricsSupervisor}>
            <Surface
              title="Key Metrics & Insights"
              className={styles.surfaceFill}
            >
              <Flex row gap="300" wrap align="stretch">
                <MetricTile
                  numericString="72%"
                  color="green"
                  link="Cases completed family finding within last 6 months"
                />
                <MetricTile
                  numericString="80%"
                  color="green"
                  link="staff that have conducted child visit within 30 days or less"
                />
                <MetricTile
                  numericString="21"
                  link="Children in care for over 24 months"
                />
                <MetricTile
                  numericString="2.7"
                  color="green"
                  link="Average Days for Investigation Completion"
                />
              </Flex>
            </Surface>
          </div>
          <div className={styles.dashboardGrid__resources}>
            <HomePageResources
              resources={resources}
              setOpenModal={setOpenModal}
            />
          </div>
          <div className={styles.dashboardGrid__caseHealthMetrics}>
            <CaseHealthMetric />
          </div>
          <div className={styles.dashboardGrid__monthlyVisits}>
            <Surface
              title="Monthly Visit Completion"
              className={styles.surfaceFill}
              bonusContent={<Icons.ExternalLink onClick={() => {}} />}
            >
              <div className={styles.graphContainer}>
                <img src={MonthlyVisits} alt="monthly visit"></img>
              </div>
            </Surface>
          </div>
          <div className={styles.dashboardGrid__afCARS}>
            <Surface
              title="AFCARS Missing Elements"
              className={styles.surfaceFill}
              bonusContent={<Icons.ExternalLink onClick={() => {}} />}
            >
              <Flex column gap="100" justify="center" align="center">
                <div className={styles.daysText}>{daysUntil(31, 3, 2025)}</div>
                <Text textStyle={"emphasis-200"}>Days until March 31</Text>
                <Link> See all (5) Profiles missing data </Link>
              </Flex>
            </Surface>
          </div>
        </div>
      );
    case "esther":
      return (
        <div className={styles.dashboardGrid}>
          <div className={styles.dashboardGrid__referrals}>
            <ResolutionOnly small over>
              <HomePageCaseManagement
                data={data}
                title={"Recently Viewed Cases"}
              />
            </ResolutionOnly>
            <ResolutionOnly small under>
              <HomePageCaseMobile
                data={data}
                title={"Recently Viewed Cases"}
                investigation={false}
              />
            </ResolutionOnly>
          </div>
          <div className={styles.dashboardGrid__todos}>
            <HomePageTodos todos={todos} setOpenTodos={setOpenTodos} />
          </div>
          <div className={styles.dashboardGrid__metricsSupervisor}>
            <Surface
              title="Key Metrics & Insights"
              className={styles.surfaceFill}
            >
              <Flex row gap="300" wrap align="stretch">
                <MetricTile
                  numericString="75%"
                  color="green"
                  link="Cases completed family finding within last 6 months"
                />
                <MetricTile
                  numericString="2"
                  link="Children in care for over 24 months"
                />
                <MetricTile
                  numericString="2"
                  link="Children missing annual physical exam"
                />
                <MetricTile
                  numericString="3.1"
                  color="green"
                  link="Average no. of placements per foster care episode"
                />
              </Flex>
            </Surface>
          </div>
          <div className={styles.dashboardGrid__resources}>
            <HomePageResources
              resources={resources}
              setOpenModal={setOpenModal}
            />
          </div>
          <div className={styles.dashboardGrid__caseHealthMetrics}>
            <CaseHealthMetric />
          </div>
          <div className={styles.dashboardGrid__monthlyVisits}>
            <Surface
              title="Monthly Visit Completion"
              className={styles.surfaceFill}
              bonusContent={<Icons.ExternalLink onClick={() => {}} />}
            >
              <div className={styles.graphContainer}>
                <img src={MonthlyVisits} alt="monthly visit"></img>
              </div>
            </Surface>
          </div>
          <div className={styles.dashboardGrid__afCARS}>
            <Surface
              title="AFCARS Missing Elements"
              className={styles.surfaceFill}
              bonusContent={<Icons.ExternalLink onClick={() => {}} />}
            >
              <Flex column gap="100" justify="center" align="center">
                <div className={styles.daysText}>{daysUntil(31, 3, 2025)}</div>
                <Text textStyle={"emphasis-200"}>Days until March 31</Text>
                <Link> See all (5) Profiles missing data </Link>
              </Flex>
            </Surface>
          </div>
        </div>
      );
    default:
      return <Text>Not logged in or slug is missing..</Text>;
  }
};
