/* eslint-disable no-nested-ternary */
import {
  HeartTable,
  Surface,
  Flex,
  Button,
  Text,
  ButtonMenu,
  Icons,
  Link,
} from "@heart/components";
import PropTypes from "prop-types";
import React from "react";
import { caseActivityNewPath } from "routes";

import styles from "../HomePage.module.scss";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const mapValueToLabel = type => {
  const labels = {
    foster_care: "Foster Care",
    prevention: "Prevention",
  };

  return labels[type] ?? null;
};

const TableBody = ({ cases }) => (
  <Tbody>
    {cases.map((item, index) => (
      <Tr key={index}>
        <Td>
          <Flex row gap="100" align="center">
            <Link href={`/case-management/${item.referralId}`}>
              {item.referralId}
            </Link>
          </Flex>
        </Td>
        <Td>
          <Flex row gap="100" align="center">
            <Link href={`/case-management/${item.referralId}`}>
              {item.referralName?.length > 0 && item.referralName[0]}
            </Link>
          </Flex>
        </Td>
        <Td>
          <Text>{mapValueToLabel(item.type)}</Text>
        </Td>
        <Td>
          <Text className={styles.greyLinkText}>
            {item?.workerAssigned?.fullName}
          </Text>
        </Td>
        <Td>
          <ButtonMenu
            rightAligned
            variant="secondary"
            buttonProps={{
              icon: Icons.ChevronDown,
              iconOnRight: true,
              children: "Actions",
              variant: "secondary",
            }}
            linkItems={[
              {
                description: "View",
                href: `/case-management/${item.referralId}`,
              },
              {
                description: "Add Case Activity",
                href: caseActivityNewPath(item.referralId),
              },
            ]}
          />
        </Td>
      </Tr>
    ))}
  </Tbody>
);

const HomePageCaseManagement = ({ title, data }) => {
  console.log(data);
  return (
    <Surface
      title="Case Activity Dashboard"
      hideTitle
      className={styles.surfaceFillDashboard}
    >
      <Flex align="center" justify="space-between">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          {title}
        </Text>
        <Button variant="secondary" href={"/case-management"}>
          View Dashboard
        </Button>
      </Flex>
      <div className={styles.overflowScroll}>
        <Table>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Case Name</Th>
              <Th>Case Type</Th>
              <Th>Workers(s) Assigned</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <TableBody cases={data} />
        </Table>
      </div>
    </Surface>
  );
};

export default HomePageCaseManagement;

TableBody.propTypes = {
  cases: PropTypes.array,
};

HomePageCaseManagement.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};
