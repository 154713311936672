import { Surface, Flex, Text, Button, Link } from "@heart/components";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { useInvestigation } from "../investigation_review/InvestigationContext";
import styles from "../investigation_review/ReviewInvestigationPage.module.scss";

const WorkerAssignments = () => {
  const { assignments } = useInvestigation();

  return (
    <Fragment>
      <Surface
        title="Worker Assignments"
        sectionId="worker-assignments"
        className={styles.surfaceContainer}
        bonusContent={<Button variant="secondary"> Edit </Button>}
      >
        <Flex column gap="100">
          <Flex row gap="300" align="center">
            <Text
              className={styles.investigationKey}
              textStyle="emphasis-100"
              textColor="neutral-600"
            >
              Worker assigned to Intake
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              <Link>Ava Johnson</Link>
            </Text>
          </Flex>

          <Flex row gap="300" align="center">
            <Text
              className={styles.investigationKey}
              textStyle="emphasis-100"
              textColor="neutral-600"
            >
              Worker Assigned
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              <Link>{assignments?.primaryWorkerAssigned}</Link>
            </Text>
          </Flex>

          <Flex row gap="300" align="center">
            <Text
              className={styles.investigationKey}
              textStyle="emphasis-100"
              textColor="neutral-600"
            >
              Supervisor Assigned
            </Text>
            <Text textStyle="body-100" textColor="neutral-600">
              <Link>{assignments?.reviewerAssigned}</Link>
            </Text>
          </Flex>
        </Flex>
      </Surface>
    </Fragment>
  );
};

WorkerAssignments.propTypes = {
  formState: PropTypes.object,
  updateFormState: PropTypes.func,
};

export default WorkerAssignments;
