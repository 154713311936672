import {
  Flex,
  Button,
  If,
  SurfaceForm,
  Actions,
  Text,
  InputFilterable,
  InputRadioGroup,
} from "@heart/components";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import RelationCard from "./RelationCard";
import styles from "./RelationsCard.module.scss";

let id = 0;

const Relations = ({
  formState,
  updateWindowsLocation,
  buttonClass,
  updateFormState,
}) => {
  const personOptions = formState.persons.map(person => ({
    value: `${person.name} ${person.middleName} ${person.lastName}`,
    label: `${person.name} ${person.middleName} ${person.lastName}`,
  }));
  const [relationship, setRelationship] = useState();

  const handleAddRelation = person => {
    updateFormState({
      ...formState,
      relations: [...formState.relations, person],
    });
  };

  const handleDelete = relationId => {
    updateFormState({
      ...formState,
      relations: formState.relations.filter(
        relation => relation.id !== relationId
      ),
    });
  };

  const [isAddingField, setIsAddingField] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!isAddingField) {
      resetFormStates();
    }
  }, [isAddingField]);

  const onSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const person1 = formData.get("person1");
    const person2 = formData.get("person2");

    if (person1 === person2) {
      setError("Person 1 and Person 2 cannot be the same.");
      return;
    }

    const key = id;
    id += 1;

    handleAddRelation({
      person1,
      parentalLineage: formData.get("parentalLineage"),
      lineageType: formData.get("lineageType"),
      relationship: formData.get("relationship"),
      paternityEstablished: formData.get("paternityEstablished"),
      putativeFather: formData.get("putativeFather"),
      person2,
      id: key,
      handleDelete,
    });

    resetFormStates();
    if (updateWindowsLocation) window.location = updateWindowsLocation;
  };

  const resetFormStates = () => {
    setIsAddingField(false);
    setRelationship(null);
    setError("");
  };

  const allowAddRelation = formState.persons.length >= 2;

  return (
    <Flex column gap="200">
      <Text textStyle="emphasis-200" textColor="neutral-600">
        Relationships
      </Text>
      <Flex gap="300" column>
        {formState.relations.map(relation => (
          <RelationCard
            key={relation.id}
            person1={relation.person1}
            parentalLineage={relation.parentalLineage}
            lineageType={relation.lineageType}
            relationship={relation.relationship}
            paternityEstablished={relation.paternityEstablished}
            putativeFather={relation.putativeFather}
            person2={relation.person2}
            id={relation.id}
            handleDelete={handleDelete}
          />
        ))}
      </Flex>

      <If condition={isAddingField}>
        <SurfaceForm
          title="New Relation"
          hideTitle
          onSubmit={onSubmit}
          actions={
            <Actions cancelAction={resetFormStates} primaryText="Save" />
          }
        >
          <Flex column gap="300" className={styles.relationsTile}>
            <InputFilterable
              id="person1"
              label="Person 1"
              name="person1"
              required
              fullWidth
              values={personOptions}
            />
            <Text textColor={"emphasis-700"} textStyle={"emphasis-100"}>
              Is the
            </Text>
            <InputFilterable
              id="parentalLineage"
              label="Parental Lineage"
              name="parentalLineage"
              required
              fullWidth
              values={ParentalLineageOptions}
            />
            <InputFilterable
              id="lineageType"
              label="Lineage Type"
              name="lineageType"
              required
              fullWidth
              values={LineageType}
            />
            <InputFilterable
              id="relationship"
              label="Relationship"
              name="relationship"
              value={relationship}
              onChange={r => {
                setRelationship(r);
              }}
              required
              fullWidth
              values={RelationshipOptions}
            />

            <If condition={relationship?.value === "father"}>
              <InputRadioGroup
                id="paternityEstablished"
                name="paternityEstablished"
                label="Has paternity been established?"
                required
                values={["Yes", "No"]}
              />
              <InputRadioGroup
                id="putativeFather"
                name="putativeFather"
                label="Is this person the putative father?"
                required
                values={["Yes", "No"]}
              />
            </If>

            <Text textColor={"emphasis-700"} textStyle={"emphasis-100"}>
              to
            </Text>
            <InputFilterable
              id="person2"
              label="Person 2"
              name="person2"
              required
              fullWidth
              values={personOptions}
            />
          </Flex>
          {error && <div style={{ color: "red" }}>{error}</div>}
        </SurfaceForm>
      </If>
      <Flex>
        <Button
          variant="secondary"
          disabled={isAddingField || !allowAddRelation}
          onClick={() => setIsAddingField(true)}
          className={buttonClass}
        >
          Add Relationship
        </Button>
      </Flex>
      {!allowAddRelation && (
        <Text textColor={"neutral-500"} textStyle={"supporting-100"}>
          You need at least 2 persons to add a relationship.
        </Text>
      )}
    </Flex>
  );
};

Relations.propTypes = {
  formState: PropTypes.object.isRequired,
  updateWindowsLocation: PropTypes.string,
  buttonClass: PropTypes.string,
  updateFormState: PropTypes.func.isRequired,
};

const ParentalLineageOptions = [
  { value: "maternal", label: "Maternal" },
  { value: "parental", label: "Parental (Non Binary)" },
  { value: "paternal", label: "Paternal" },
];

const LineageType = [
  { value: "adopted", label: "Adopted" },
  { value: "alleged", label: "Alleged" },
  { value: "biological", label: "Biological" },
  { value: "half", label: "Half" },
  { value: "marriage/affinity", label: "Marriage/Affinity" },
  { value: "presumed", label: "Presumed" },
  { value: "step", label: "Step" },
  { value: "unknown", label: "Unknown" },
];

const RelationshipOptions = [
  { value: "aunt", label: "Aunt" },
  { value: "child", label: "Child" },
  { value: "cousin", label: "Cousin" },
  { value: "daughter", label: "Daughter" },
  { value: "father", label: "Father" },
  { value: "grandfather", label: "Grandfather" },
  { value: "grandmother", label: "Grandmother" },
  { value: "grandparent", label: "Grandparent" },
  { value: "mother", label: "Mother" },
  { value: "nephew", label: "Nephew" },
  { value: "niece", label: "Niece" },
  { value: "other", label: "Other" },
  { value: "parent", label: "Parent" },
  { value: "partner", label: "Partner" },
  { value: "sibling", label: "Sibling" },
  { value: "son", label: "Son" },
  { value: "uncle", label: "Uncle" },
  { value: "unknown", label: "Unknown" },
];

export default Relations;
