import { Surface, Text, Flex, Button } from "@heart/components";

import ProviderInformationTable from "@components/shared/ProviderInformationTable";

import { usePersonInformation } from "../PersonContext";

const Medical = () => {
  const { formState } = usePersonInformation();
  const providers = formState.providers || [];

  // Filter for medical providers (medical-doctor or other-medical)
  const medicalProviders = providers.filter(provider => {
    const { providerType } = provider;
    const isDental = providerType === "dentist";
    const isMental =
      providerType?.includes("mental") ||
      providerType === "therapist-psychologist" ||
      providerType === "psychiatrist";

    return (
      providerType === "medical-doctor" ||
      providerType === "other-medical" ||
      (!isDental && !isMental)
    );
  });

  return (
    <Surface title="Medical" hideTitle sectionId="medical">
      <Flex column gap="200" align="start">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Medical
        </Text>
        {medicalProviders.length > 0 ? (
          <ProviderInformationTable providers={medicalProviders} />
        ) : null}
        <Button>Add Provider</Button>
      </Flex>
    </Surface>
  );
};

export default Medical;
