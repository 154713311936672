const protectedTribes = [
  "Absentee-Shawnee Tribe of Indians of Oklahoma",
  "Agdaagux Tribe of King Cove",
  "Agua Caliente Band of Cahuilla Indians of the Agua Caliente Indian Reservation, California",
  "Ak-Chin Indian Community",
  "Akiachak Native Community",
  "Akiak Native Community",
  "Alabama-Coushatta Tribe of Texas",
  "Alabama-Quassarte Tribal Town",
  "Alatna Village",
  "Algaaciq Native Village (St. Mary's)",
  "Allakaket Village",
  "Alturas Indian Rancheria, California",
  "Alutiiq Tribe of Old Harbor",
  "Angoon Community Association",
  "Anvik Village",
  "Apache Tribe of Oklahoma",
  "Arctic Village (See Native Village of Venetie Tribal Government)",
  "Asa'carsarmiut Tribe",
  "Assiniboine & Sioux Tribes of the Fort Peck Indian Reservation, Montana",
  "Augustine Band of Cahuilla Indians, California",
  "Bad River Band of the Lake Superior Tribe of Chippewa Indians of the Bad River Reservation, Wisconsin",
  "Bay Mills Indian Community, Michigan",
  "Bear River Band of the Rohnerville Rancheria, California",
  "Beaver Village",
  "Berry Creek Rancheria of Maidu Indians of California",
  "Big Lagoon Rancheria, California",
  "Big Pine Paiute Tribe of the Owens Valley",
  "Big Sandy Rancheria of Western Mono Indians of California",
  "Big Valley Band of Pomo Indians of the Big Valley Rancheria, California",
  "Birch Creek Tribe",
  "Bishop Paiute Tribe",
  "Blackfeet Tribe of the Blackfeet Indian Reservation of Montana",
  "Blue Lake Rancheria, California",
  "Bridgeport Indian Colony",
  "Buena Vista Rancheria of Me-Wuk Indians of California",
  "Burns Paiute Tribe",
  "Cabazon Band of Cahuilla Indians",
  "Cachil DeHe Band of Wintun Indians of the Colusa Indian Community of the Colusa Rancheria, California",
  "Caddo Nation of Oklahoma",
  "Cahto Tribe of the Laytonville Rancheria",
  "Cahuilla Band of Indians",
  "California Valley Miwok Tribe, California",
  "Campo Band of Diegueno Mission Indians of the Campo Indian Reservation, California",
  "Capitan Grande Band of Diegueno Mission Indians of California",
  "Capitan Grande Band of Diegueno Mission Indians of California (Barona Group of Capitan Grande Band of Mission Indians of the Barona Reservation, California)",
  "Capitan Grande Band of Diegueno Mission Indians of California: Viejas (Baron Long) Group of Capitan Grande Band of Mission Indians of the Viejas Reservation, California",
  "Catawba Indian Nation (aka Catawba Indian Tribe of South Carolina)",
  "Cayuga Nation",
  "Cedarville Rancheria, California",
  "Central Council of the Tlingit & Haida Indian Tribes",
  "Chalkyitsik Village",
  "Cheesh-Na Tribe",
  "Chemehuevi Indian Tribe of the Chemehuevi Reservation, California",
  "Cher-Ae Heights Indian Community of the Trinidad Rancheria, California",
  "Cherokee Nation",
  "Chevak Native Village",
  "Cheyenne and Arapaho Tribes, Oklahoma",
  "Cheyenne River Sioux Tribe of the Cheyenne River Reservation, South Dakota",
  "Chickahominy Indian Tribe",
  "Chickahominy Indian Tribe - Eastern Division",
  "Chickaloon Native Village",
  "Chicken Ranch Rancheria of Me-Wuk Indians of California",
  "Chignik Bay Tribal Council",
  "Chignik Lake Village",
  "Chilkat Indian Village (Klukwan)",
  "Chilkoot Indian Association (Haines)",
  "Chinik Eskimo Community (Golovin)",
  "Chippewa Cree Indians of the Rocky Boy's Reservation, Montana",
  "Chitimacha Tribe of Louisiana",
  "Chuloonawick Native Village",
  "Circle Native Community",
  "Citizen Potawatomi Nation, Oklahoma",
  "Cloverdale Rancheria of Pomo Indians of California",
  "Cocopah Tribe of Arizona",
  "Coeur D'Alene Tribe",
  "Cold Springs Rancheria of Mono Indians of California",
  "Colorado River Indian Tribes of the Colorado River Indian Reservation, Arizona and California",
  "Comanche Nation, Oklahoma",
  "Confederated Salish and Kootenai Tribes of the Flathead Reservation",
  "Confederated Tribes and Bands of the Yakama Nation",
  "Confederated Tribes of Siletz Indians of Oregon",
  "Confederated Tribes of the Chehalis Reservation",
  "Confederated Tribes of the Colville Reservation",
  "Confederated Tribes of the Coos, Lower Umpqua and Siuslaw Indians",
  "Confederated Tribes of the Goshute Reservation, Nevada and Utah",
  "Confederated Tribes of the Grand Ronde Community of Oregon",
  "Confederated Tribes of the Umatilla Indian Reservation",
  "Confederated Tribes of the Warm Springs Reservation of Oregon",
  "Coquille Indian Tribe",
  "Coushatta Tribe of Louisiana",
  "Cow Creek Band of Umpqua Tribe of Indians",
  "Cowlitz Indian Tribe",
  "Coyote Valley Band of Pomo Indians of California",
  "Craig Tribal Association",
  "Crow Creek Sioux Tribe of the Crow Creek Reservation, South Dakota",
  "Crow Tribe of Montana",
  "Curyung Tribal Council",
  "Delaware Nation, Oklahoma",
  "Delaware Tribe of Indians",
  "Douglas Indian Association",
  "Dry Creek Rancheria Band of Pomo Indians, California",
  "Duckwater Shoshone Tribe of the Duckwater Reservation, Nevada",
  "Eastern Band of Cherokee Indians",
  "Eastern Shawnee Tribe of Oklahoma",
  "Eastern Shoshone Tribe of the Wind River Reservation, Wyoming",
  "Egegik Village",
  "Eklutna Native Village",
  "Elem Indian Colony of Pomo Indians of the Sulphur Bank Rancheria, California",
  "Elk Valley Rancheria, California",
  "Ely Shoshone Tribe of Nevada",
  "Emmonak Village",
  "Enterprise Rancheria of Maidu Indians of California",
  "Evansville Village (aka Bettles Field)",
  "Ewiiaapaayp Band of Kumeyaay Indians, California",
  "Federated Indians of Graton Rancheria, California",
  "Flandreau Santee Sioux Tribe of South Dakota",
  "Forest County Potawatomi Community, Wisconsin",
  "Fort Belknap Indian Community of the Fort Belknap Reservation of Montana",
  "Fort Bidwell Indian Community of the Fort Bidwell Reservation of California",
  "Fort Independence Indian Community of Paiute Indians of the Fort Independence Reservation, California",
  "Fort McDermitt Paiute and Shoshone Tribes of the Fort McDermitt Indian Reservation, Nevada and Oregon",
  "Fort McDowell Yavapai Nation, Arizona",
  "Fort Mojave Indian Tribe of Arizona, California & Nevada",
  "Fort Sill Apache Tribe of Oklahoma",
  "Gila River Indian Community of the Gila River Indian Reservation, Arizona",
  "Grand Traverse Band of Ottawa and Chippewa Indians, Michigan",
  "Greenville Rancheria",
  "Grindstone Indian Rancheria of Wintun-Wailaki Indians of California",
  "Guidiville Rancheria of California",
  "Gulkana Village Council",
  "Habematolel Pomo of Upper Lake, California",
  "Hannahville Indian Community, Michigan",
  "Havasupai Tribe of the Havasupai Reservation, Arizona",
  "Healy Lake Village",
  "Ho-Chunk Nation of Wisconsin",
  "Hoh Indian Tribe",
  "Holy Cross Tribe",
  "Hoonah Indian Association",
  "Hoopa Valley Tribe, California",
  "Hopi Tribe of Arizona",
  "Hopland Band of Pomo Indians, California",
  "Houlton Band of Maliseet Indians",
  "Hualapai Indian Tribe of the Hualapai Indian Reservation, Arizona",
  "Hughes Village",
  "Huslia Village",
  "Hydaburg Cooperative Association",
  "Igiugig Village",
  "Iipay Nation of Santa Ysabel, California",
  "Inaja Band of Diegueno Mission Indians of the Inaja and Cosmit Reservation, California",
  "Inupiat Community of the Arctic Slope",
  "Ione Band of Miwok Indians of California",
  "Iowa Tribe of Kansas and Nebraska",
  "Iowa Tribe of Oklahoma",
  "Iqugmiut Traditional Council",
  "Ivanof Bay Tribe",
  "Jackson Band of Miwuk Indians",
  "Jamestown S'Klallam Tribe",
  "Jamul Indian Village of California",
  "Jena Band of Choctaw Indians",
  "Jicarilla Apache Nation, New Mexico",
  "Kaguyak Village",
  "Kaibab Band of Paiute Indians of the Kaibab Indian Reservation, Arizona",
  "Kaktovik Village (aka Barter Island)",
  "Kalispel Indian Community of the Kalispel Reservation",
  "Karuk Tribe",
  "Kashia Band of Pomo Indians of the Stewarts Point Rancheria, California",
  "Kasigluk Traditional Elders Council",
  "Kaw Nation, Oklahoma",
  "Kenaitze Indian Tribe",
  "Ketchikan Indian Community",
  "Keweenaw Bay Indian Community, Michigan",
  "Kialegee Tribal Town",
  "Kickapoo Traditional Tribe of Texas",
  "Kickapoo Tribe of Indians of the Kickapoo Reservation in Kansas",
  "Kickapoo Tribe of Oklahoma",
  "King Island Native Community",
  "King Salmon Tribe",
  "Kiowa Indian Tribe of Oklahoma",
  "Klamath Tribes",
  "Klawock Cooperative Association",
  "Kletsel Dehe Wintun Nation of the Cortina Rancheria",
  "Knik Tribe",
  "Koi Nation of Northern California",
  "Kokhanok Village",
  "Kootenai Tribe of Idaho",
  "Koyukuk Native Village",
  "La Jolla Band of Luiseno Indians, California",
  "La Posta Band of Diegueno Mission Indians of the La Posta Indian Reservation, California",
  "Lac Courte Oreilles Band of Lake Superior Chippewa Indians of Wisconsin",
  "Lac du Flambeau Band of Lake Superior Chippewa Indians of the Lac du Flambeau Reservation of Wisconsin",
  "Lac Vieux Desert Band of Lake Superior Chippewa Indians of Michigan",
  "Las Vegas Tribe of Paiute Indians of the Las Vegas Indian Colony, Nevada",
  "Levelock Village",
  "Lime Village",
  "Little River Band of Ottawa Indians, Michigan",
  "Little Shell Tribe of Chippewa Indians of Montana",
  "Little Traverse Bay Bands of Odawa Indians, Michigan",
  "Lone Pine Paiute-Shoshone Tribe",
  "Los Coyotes Band of Cahuilla and Cupeno Indians, California",
  "Louden Tribe (previously listed as Galena Village (aka Louden Village))",
  "Lovelock Paiute Tribe of the Lovelock Indian Colony, Nevada",
  "Lower Brule Sioux Tribe of the Lower Brule Reservation, South Dakota",
  "Lower Elwha Tribal Community",
  "Lower Sioux Indian Community in the State of Minnesota",
  "Lummi Tribe of the Lummi Reservation",
  "Lytton Rancheria of California",
  "Makah Indian Tribe of the Makah Indian Reservation",
  "Manchester Band of Pomo Indians of the Manchester Rancheria, California",
  "Manley Hot Springs Village",
  "Manokotak Village",
  "Manzanita Band of Diegueno Mission Indians of the Manzanita Reservation, California",
  "Mashantucket Pequot Indian Tribe",
  "Mashpee Wampanoag Tribe",
  "Match-e-be-nash-she-wish Band of Pottawatomi Indians of Michigan",
  "McGrath Native Village",
  "Mechoopda Indian Tribe of Chico Rancheria, California",
  "Menominee Indian Tribe of Wisconsin",
  "Mentasta Traditional Council",
  "Mesa Grande Band of Diegueno Mission Indians of the Mesa Grande Reservation, California",
  "Mescalero Apache Tribe of the Mescalero Reservation, New Mexico",
  "Metlakatla Indian Community, Annette Island Reserve",
  "Mi'kmaq Nation",
  "Miami Tribe of Oklahoma",
  "Miccosukee Tribe of Indians",
  "Middletown Rancheria of Pomo Indians of California",
  "Minnesota Chippewa Tribe - Bois Forte Band (Nett Lake)",
  "Minnesota Chippewa Tribe - Fond du Lac Band",
  "Minnesota Chippewa Tribe - Grand Portage Band",
  "Minnesota Chippewa Tribe - Leech Lake Band",
  "Minnesota Chippewa Tribe - Mille Lacs Band",
  "Minnesota Chippewa Tribe - White Earth Band",
  "Minnesota Chippewa Tribe, Minnesota",
  "Mississippi Band of Choctaw Indians",
  "Moapa Band of Paiute Indians of the Moapa River Indian Reservation, Nevada",
  "Modoc Nation",
  "Mohegan Tribe of Indians of Connecticut",
  "Monacan Indian Nation",
  "Mooretown Rancheria of Maidu Indians of California",
  "Morongo Band of Mission Indians, California",
  "Muckleshoot Indian Tribe",
  "Naknek Native Village",
  "Nansemond Indian Nation",
  "Narragansett Indian Tribe",
  "Native Village of Afognak",
  "Native Village of Akhiok",
  "Native Village of Akutan",
  "Native Village of Aleknagik",
  "Native Village of Ambler",
  "Native Village of Atka",
  "Native Village of Atqasuk",
  "Native Village of Barrow Inupiat Traditional Government",
  "Native Village of Belkofski",
  "Native Village of Brevig Mission",
  "Native Village of Buckland",
  "Native Village of Cantwell",
  "Native Village of Chenega (aka Chanega)",
  "Native Village of Chignik Lagoon",
  "Native Village of Chitina",
  "Native Village of Chuathbaluk (Russian Mission, Kuskokwim)",
  "Native Village of Council",
  "Native Village of Deering",
  "Native Village of Diomede (aka Inalik)",
  "Native Village of Eagle",
  "Native Village of Eek",
  "Native Village of Ekuk",
  "Native Village of Ekwok",
  "Native Village of Elim",
  "Native Village of Eyak (Cordova)",
  "Native Village of False Pass",
  "Native Village of Fort Yukon",
  "Native Village of Gakona",
  "Native Village of Gambell",
  "Native Village of Georgetown",
  "Native Village of Goodnews Bay",
  "Native Village of Hamilton",
  "Native Village of Hooper Bay",
  "Native Village of Kanatak",
  "Native Village of Karluk",
  "Native Village of Kiana",
  "Native Village of Kipnuk",
  "Native Village of Kivalina",
  "Native Village of Kluti Kaah (aka Copper Center)",
  "Native Village of Kobuk",
  "Native Village of Kongiganak",
  "Native Village of Kotzebue",
  "Native Village of Koyuk",
  "Native Village of Kwigillingok",
  "Native Village of Kwinhagak (aka Quinhagak)",
  "Native Village of Larsen Bay",
  "Native Village of Marshall (aka Fortuna Ledge)",
  "Native Village of Mary's Igloo",
  "Native Village of Mekoryuk",
  "Native Village of Minto",
  "Native Village of Nanwalek (aka English Bay)",
  "Native Village of Napaimute",
  "Native Village of Napakiak",
  "Native Village of Napaskiak",
  "Native Village of Nelson Lagoon",
  "Native Village of Nightmute",
  "Native Village of Nikolski",
  "Native Village of Noatak",
  "Native Village of Nuiqsut (aka Nooiksut)",
  "Native Village of Nunam Iqua",
  "Native Village of Nunapitchuk",
  "Native Village of Ouzinkie",
  "Native Village of Paimiut",
  "Native Village of Perryville",
  "Native Village of Pilot Point",
  "Native Village of Point Hope",
  "Native Village of Point Lay",
  "Native Village of Port Graham",
  "Native Village of Port Heiden",
  "Native Village of Port Lions",
  "Native Village of Ruby",
  "Native Village of Saint Michael",
  "Native Village of Savoonga",
  "Native Village of Scammon Bay",
  "Native Village of Selawik",
  "Native Village of Shaktoolik",
  "Native Village of Shishmaref",
  "Native Village of Shungnak",
  "Native Village of Stevens",
  "Native Village of Tanacross",
  "Native Village of Tanana",
  "Native Village of Tatitlek",
  "Native Village of Tazlina",
  "Native Village of Teller",
  "Native Village of Tetlin",
  "Native Village of Tuntutuliak",
  "Native Village of Tununak",
  "Native Village of Tyonek",
  "Native Village of Unalakleet",
  "Native Village of Unga",
  "Native Village of Venetie Tribal Government (Arctic Village and Village of Venetie)",
  "Native Village of Wales",
  "Native Village of White Mountain",
  "Navajo Nation, Arizona, New Mexico & Utah",
  "Nenana Native Association",
  "New Koliganek Village Council",
  "New Stuyahok Village",
  "Newhalen Village",
  "Newtok Village",
  "Nez Perce Tribe",
  "Nikolai Village",
  "Ninilchik Village",
  "Nisqually Indian Tribe",
  "Nome Eskimo Community",
  "Nondalton Village",
  "Nooksack Indian Tribe",
  "Noorvik Native Community",
  "Northern Arapaho Tribe of the Wind River Reservation, Wyoming",
  "Northern Cheyenne Tribe of the Northern Cheyenne Indian Reservation, Montana",
  "Northfork Rancheria of Mono Indians of California",
  "Northway Village",
  "Northwestern Band of the Shoshone Nation",
  "Nottawaseppi Huron Band of the Potawatomi, Michigan",
  "Nulato Village",
  "Nunakauyarmiut Tribe",
  "Oglala Sioux Tribe",
  "Ohkay Owingeh, New Mexico",
  "Omaha Tribe of Nebraska",
  "Oneida Indian Nation",
  "Oneida Nation",
  "Onondaga Nation",
  "Organized Village of Grayling (aka Holikachuk)",
  "Organized Village of Kake",
  "Organized Village of Kasaan",
  "Organized Village of Kwethluk",
  "Organized Village of Saxman",
  "Orutsararmiut Traditional Native Council",
  "Oscarville Traditional Village",
  "Otoe-Missouria Tribe of Indians, Oklahoma",
  "Ottawa Tribe of Oklahoma",
  "Paiute Indian Tribe of Utah (Cedar Band of Paiutes, Kanosh Band of Paiutes, Koosharem Band of Paiutes, Indian Peaks Band of Paiutes, and Shivwits Band of Paiutes)",
  "Paiute-Shoshone Tribe of the Fallon Reservation and Colony, Nevada",
  "Pala Band of Mission Indians",
  "Pamunkey Indian Tribe",
  "Pascua Yaqui Tribe of Arizona",
  "Paskenta Band of Nomlaki Indians of California",
  "Passamaquoddy Tribe",
  "Passamaquoddy Tribe - Indian Township",
  "Passamaquoddy Tribe - Pleasant Point",
  "Pauloff Harbor Village",
  "Pauma Band of Luiseno Mission Indians of the Pauma & Yuima Reservation, California",
  "Pawnee Nation of Oklahoma",
  "Pechanga Band of Indians",
  "Pedro Bay Village",
  "Penobscot Nation",
  "Peoria Tribe of Indians of Oklahoma",
  "Petersburg Indian Association",
  "Picayune Rancheria of Chukchansi Indians of California",
  "Pilot Station Traditional Village",
  "Pinoleville Pomo Nation, California",
  "Pit River Tribe, California (includes XL Ranch, Big Bend, Likely, Lookout, Montgomery Creek, and Roaring Creek Rancherias)",
  "Pitka's Point Traditional Council",
  "Platinum Traditional Village",
  "Poarch Band of Creek Indians",
  "Pokagon Band of Potawatomi Indians, Michigan and Indiana",
  "Ponca Tribe of Indians of Oklahoma",
  "Ponca Tribe of Nebraska",
  "Port Gamble S'Klallam Tribe",
  "Portage Creek Village (aka Ohgsenakale)",
  "Potter Valley Tribe, California",
  "Prairie Band Potawatomi Nation",
  "Prairie Island Indian Community in the State of Minnesota",
  "Pribilof Islands Aleut Communities",
  "Pueblo of Acoma, New Mexico",
  "Pueblo of Cochiti, New Mexico",
  "Pueblo of Isleta, New Mexico",
  "Pueblo of Jemez, New Mexico",
  "Pueblo of Laguna, New Mexico",
  "Pueblo of Nambe, New Mexico",
  "Pueblo of Picuris, New Mexico",
  "Pueblo of Pojoaque, New Mexico",
  "Pueblo of San Felipe, New Mexico",
  "Pueblo of San Ildefonso, New Mexico",
  "Pueblo of Sandia, New Mexico",
  "Pueblo of Santa Ana, New Mexico",
  "Pueblo of Santa Clara, New Mexico",
  "Pueblo of Taos, New Mexico",
  "Pueblo of Tesuque, New Mexico",
  "Pueblo of Zia, New Mexico",
  "Puyallup Tribe of the Puyallup Reservation",
  "Pyramid Lake Paiute Tribe of the Pyramid Lake Reservation, Nevada",
  "Qagan Tayagungin Tribe of Sand Point",
  "Qawalangin Tribe of Unalaska",
  "Quapaw Nation",
  "Quartz Valley Indian Community of the Quartz Valley Reservation of California",
  "Quechan Tribe of the Fort Yuma Indian Reservation, California & Arizona",
  "Quileute Tribe of the Quileute Reservation",
  "Quinault Indian Nation",
  "Ramah Navajo Chapter of the Navajo Nation",
  "Ramona Band of Cahuilla, California",
  "Rampart Village",
  "Rappahannock Tribe, Inc.",
  "Red Cliff Band of Lake Superior Chippewa Indians of Wisconsin",
  "Red Lake Band of Chippewa Indians, Minnesota",
  "Redding Rancheria, California",
  "Redwood Valley or Little River Band of Pomo Indians of the Redwood Valley Rancheria California",
  "Reno-Sparks Indian Colony, Nevada",
  "Resighini Rancheria, California",
  "Rincon Band of Luiseno Mission Indians of Rincon Reservation, California",
  "Robinson Rancheria",
  "Rosebud Sioux Tribe of the Rosebud Indian Reservation, South Dakota",
  "Round Valley Indian Tribes, Round Valley Reservation, California",
  "Sac & Fox Nation of Missouri in Kansas and Nebraska",
  "Sac & Fox Nation, Oklahoma",
  "Sac & Fox Tribe of the Mississippi in Iowa",
  "Saginaw Chippewa Indian Tribe of Michigan",
  "Saint George Island (See Pribilof Islands Aleut Communities of St. Paul & St. George Islands)",
  "Saint Paul Island (See Pribilof Islands Aleut Communities of St. Paul & St. George Islands)",
  "Saint Regis Mohawk Tribe",
  "Salamatof Tribe",
  "Salt River Pima-Maricopa Indian Community of the Salt River Reservation, Arizona",
  "Samish Indian Nation",
  "San Carlos Apache Tribe of the San Carlos Reservation, Arizona",
  "San Juan Southern Paiute Tribe of Arizona",
  "San Pasqual Band of Diegueno Mission Indians of California",
  "Santa Rosa Band of Cahuilla Indians, California",
  "Santa Rosa Indian Community of the Santa Rosa Rancheria, California",
  "Santa Ynez Band of Chumash Mission Indians of the Santa Ynez Reservation, California",
  "Santee Sioux Nation, Nebraska",
  "Santo Domingo Pueblo",
  "Sauk-Suiattle Indian Tribe",
  "Sault Ste. Marie Tribe of Chippewa Indians, Michigan",
  "Scotts Valley Band of Pomo Indians of California",
  "Seldovia Village Tribe",
  "Seminole Tribe of Florida",
  "Seneca Nation of Indians",
  "Seneca-Cayuga Nation",
  "Shageluk Native Village",
  "Shakopee Mdewakanton Sioux Community of Minnesota",
  "Shawnee Tribe",
  "Sherwood Valley Rancheria of Pomo Indians of California",
  "Shingle Springs Band of Miwok Indians, Shingle Springs Rancheria (Verona Tract), California",
  "Shinnecock Indian Nation",
  "Shoalwater Bay Indian Tribe of the Shoalwater Bay Indian Reservation",
  "Shoshone-Bannock Tribes of the Fort Hall Reservation",
  "Shoshone-Paiute Tribes of the Duck Valley Reservation, Nevada",
  "Sisseton-Wahpeton Oyate of the Lake Traverse Reservation, South Dakota",
  "Sitka Tribe of Alaska",
  "Skagway Village",
  "Skokomish Indian Tribe",
  "Skull Valley Band of Goshute Indians of Utah",
  "Snoqualmie Indian Tribe",
  "Soboba Band of Luiseno Indians, California",
  "Sokaogon Chippewa Community, Wisconsin",
  "South Naknek Village",
  "Southern Ute Indian Tribe of the Southern Ute Reservation, Colorado",
  "Spirit Lake Tribe, North Dakota",
  "Spokane Tribe of the Spokane Reservation",
  "Squaxin Island Tribe of the Squaxin Island Reservation",
  "St. Croix Chippewa Indians of Wisconsin",
  "Standing Rock Sioux Tribe of North & South Dakota",
  "Stebbins Community Association",
  "Stillaguamish Tribe of Indians of Washington",
  "Stockbridge Munsee Community, Wisconsin",
  "Summit Lake Paiute Tribe of Nevada",
  "Sun'aq Tribe of Kodiak",
  "Suquamish Indian Tribe of the Port Madison Reservation",
  "Susanville Indian Rancheria, California",
  "Swinomish Indian Tribal Community",
  "Sycuan Band of the Kumeyaay Nation",
  "Table Mountain Rancheria",
  "Takotna Village",
  "Tangirnaq Native Village",
  "Te-Moak Tribe of Western Shoshone Indians of Nevada (Four constituent bands: Battle Mountain Band; Elko Band; South Fork Band and Wells Band)",
  "Tejon Indian Tribe",
  "Telida Village",
  "The Chickasaw Nation",
  "The Choctaw Nation of Oklahoma",
  "The Muscogee (Creek) Nation",
  "The Osage Nation",
  "The Seminole Nation of Oklahoma",
  "Thlopthlocco Tribal Town",
  "Three Affiliated Tribes of the Fort Berthold Reservation, North Dakota",
  "Timbisha Shoshone Tribe",
  "Tohono O'odham Nation of Arizona",
  "Tolowa Dee-ni' Nation",
  "Tonawanda Band of Seneca",
  "Tonkawa Tribe of Indians of Oklahoma",
  "Tonto Apache Tribe of Arizona",
  "Torres Martinez Desert Cahuilla Indians, California",
  "Traditional Village of Togiak",
  "Tulalip Tribes of Washington",
  "Tule River Indian Tribe of the Tule River Reservation, California",
  "Tuluksak Native Community",
  "Tunica-Biloxi Indian Tribe",
  "Tuolumne Band of Me-Wuk Indians of the Tuolumne Rancheria of California",
  "Turtle Mountain Band of Chippewa Indians of North Dakota",
  "Tuscarora Nation",
  "Twenty-Nine Palms Band of Mission Indians of California",
  "Twin Hills Village",
  "Ugashik Village",
  "Umkumiut Native Village",
  "United Auburn Indian Community of the Auburn Rancheria of California",
  "United Keetoowah Band of Cherokee Indians in Oklahoma",
  "Upper Mattaponi Tribe",
  "Upper Sioux Community, Minnesota",
  "Upper Skagit Indian Tribe",
  "Ute Indian Tribe of the Uintah & Ouray Reservation, Utah",
  "Ute Mountain Ute Tribe",
  "Utu Utu Gwaitu Paiute Tribe of the Benton Paiute Reservation, California",
  "Village of Alakanuk",
  "Village of Anaktuvuk Pass",
  "Village of Aniak",
  "Village of Atmautluak",
  "Village of Bill Moore's Slough",
  "Village of Chefornak",
  "Village of Clarks Point",
  "Village of Crooked Creek",
  "Village of Dot Lake",
  "Village of Iliamna",
  "Village of Kalskag",
  "Village of Kaltag",
  "Village of Kotlik",
  "Village of Lower Kalskag",
  "Village of Ohogamiut",
  "Village of Red Devil",
  "Village of Sleetmute",
  "Village of Solomon",
  "Village of Stony River",
  "Village of Venetie (See Native Village of Venetie Tribal Government)",
  "Village of Wainwright",
  "Walker River Paiute Tribe of the Walker River Reservation, Nevada",
  "Wampanoag Tribe of Gay Head (Aquinnah)",
  "Washoe Tribe of Nevada & California (Carson Colony, Dresslerville Colony, Woodfords Community, Stewart Community, & Washoe Ranches)",
  "White Mountain Apache Tribe of the Fort Apache Reservation, Arizona",
  "Wichita and Affiliated Tribes (Wichita, Keechi, Waco & Tawakonie), Oklahoma",
  "Wilton Rancheria, California",
  "Winnebago Tribe of Nebraska",
  "Winnemucca Indian Colony of Nevada",
  "Wiyot Tribe, California",
  "Wrangell Cooperative Association",
  "Wyandotte Nation",
  "Yakutat Tlingit Tribe",
  "Yankton Sioux Tribe of South Dakota",
  "Yavapai-Apache Nation of the Camp Verde Indian Reservation, Arizona",
  "Yavapai-Prescott Indian Tribe",
  "Yerington Paiute Tribe of the Yerington Colony and Campbell Ranch, Nevada",
  "Yocha Dehe Wintun Nation, California",
  "Yomba Shoshone Tribe of the Yomba Reservation, Nevada",
  "Ysleta del Sur Pueblo",
  "Yuhaaviatam of San Manuel Nation",
  "Yupiit of Andreafski",
  "Yurok Tribe of the Yurok Reservation, California",
  "Zuni Tribe of the Zuni Reservation, New Mexico",
  "White Earth Reservation Business Committee",
];

export default protectedTribes;
