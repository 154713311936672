import {
  Surface,
  Flex,
  Text,
  HeartTable,
  TableCellList,
  LabeledContent,
  Icons,
  Button,
} from "@heart/components";
import PROVIDER_TYPES_LABEL from "@utils/providerTypes";

import { formatDateTimeAsShortDate } from "@lib/dates";

import { usePersonInformation } from "../PersonContext";

const { Table, Td, Th, Thead, Tr, Tbody } = HeartTable;

const Visits = () => {
  const { formState } = usePersonInformation();

  return (
    <Surface title="Visits" hideTitle sectionId="Visits">
      <Flex column gap="200" align="start">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Visits
        </Text>
        {formState.visits?.length > 0 ? (
          <Table>
            <Thead>
              <Tr>
                <Th>Date of Visit</Th>
                <Th>Provider (Name/Type)</Th>
                <Th>Diagnoses or Medications?</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {formState.visits.map((visit, i) => (
                <Tr key={i}>
                  <Td>{formatDateTimeAsShortDate(visit.activityDate)}</Td>
                  <Td>
                    {visit.provider ? (
                      <TableCellList
                        items={[
                          <LabeledContent
                            key="name"
                            label="Name"
                            content={visit.provider.name}
                          />,
                          <LabeledContent
                            key="providerType"
                            label="Provider Type"
                            content={PROVIDER_TYPES_LABEL[visit.provider.type]}
                          />,
                        ]}
                      />
                    ) : null}
                  </Td>
                  <Td>
                    <TableCellList
                      items={[
                        <LabeledContent
                          key="diagnoses"
                          label="Diagnoses"
                          content={visit.diagnosisName || " "}
                        />,
                        <LabeledContent
                          key="medicationName"
                          label="Medication Name"
                          content={visit.medicationName || " "}
                        />,
                      ]}
                    />
                  </Td>
                  <Td alignRight>
                    <Flex justify="end">
                      <Icons.Edit onClick={() => {}} />
                      <Icons.Trash onClick={() => {}} />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : null}
        <Button>Add Visit</Button>
      </Flex>
    </Surface>
  );
};

export default Visits;
