import PropTypes from "prop-types";
import React from "react";

import styles from "./styles/SharedStyles.module.scss";

const ButtonIcon = ({
  svg: SvgIcon,
  onClick = () => {},
  href = "",
  width = 24,
  height = 24,
}) => {
  const content = (
    <img src={SvgIcon} width={width} height={height} alt="button-with-icon" />
  );

  return href ? (
    <a href={href} className={styles.clickableIconButton}>
      {content}
    </a>
  ) : (
    <button className={styles.clickableIconButton} onClick={onClick}>
      {content}
    </button>
  );
};

ButtonIcon.propTypes = {
  svg: PropTypes.elementType.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ButtonIcon;
