/* eslint-disable react/prop-types */
import { Button, Flex, Link, Pill, Surface } from "@heart/components";
import React from "react";
import {
  caseActivityNewPath,
  investigationReviewPath,
  investigationsPath,
} from "routes";

import ButtonIcon from "@components/shared/ButtonIcon";
import EyeIcon from "@components/shared/assets/eye";
import IconPlus from "@components/shared/assets/icon_plus";

import styles from "../HomePage.module.scss";
import { formatPill, isNewCaseStatus } from "./HomePageCaseDashboard";

// Dates appear in a pill; dates overdue are orange; 0-10 days are yellow; 11+ days are blue.
// Warning = Orange = Negative
// Alert = Yellow = 0-10 days
// Info = Blue = 11+ days

const HomePageCaseMobile = ({ data, title, investigation = true }) => {
  const CaseRowItem = ({ children }) => (
    <Flex
      row
      align="center"
      justify="space-between"
      gap="100"
      className={styles.caseRowItem}
    >
      {children}
    </Flex>
  );

  return (
    <Surface title={title} className={styles.surfaceFill}>
      <Flex column className={styles.caseMobileFlexColumn}>
        {data.map((item, index) => (
          <CaseRowItem key={index}>
            <Flex row justify="start" align="center" gap="100">
              {investigation && (
                <React.Fragment>
                  {isNewCaseStatus(item.caseStatus) && (
                    <Pill variant={"alert"} text="New" />
                  )}
                  {formatPill(item.priorityRecommendation)}
                </React.Fragment>
              )}
              <Link href={investigationReviewPath(item.referralId)}>
                {item.referralName?.length > 0 && item.referralName[0]}
              </Link>
            </Flex>
            {investigation ? (
              <Flex row gap="0" align="center" justify="center">
                {isNewCaseStatus(item.caseStatus) ? (
                  <React.Fragment>
                    <ButtonIcon
                      svg={EyeIcon}
                      href={investigationReviewPath(item.referralId)}
                    />
                    <Button
                      href={investigationReviewPath(item.referralId)}
                      variant="secondary"
                      className={styles.acceptButton}
                    >
                      Accept
                    </Button>
                  </React.Fragment>
                ) : (
                  <ButtonIcon
                    svg={IconPlus}
                    href={caseActivityNewPath(item.referralId)}
                  />
                )}
              </Flex>
            ) : (
              <ButtonIcon
                svg={IconPlus}
                href={caseActivityNewPath(item.referralId)}
              />
            )}
          </CaseRowItem>
        ))}
        <Flex row justify="start">
          {investigation ? (
            <Link
              href={investigationsPath()}
              textStyle="body-100"
              textColor="neutral-600"
            >
              See all Referrals
            </Link>
          ) : (
            <Link
              href={"/case-management"}
              textStyle="body-100"
              textColor="neutral-600"
            >
              See all Cases
            </Link>
          )}
        </Flex>
      </Flex>
    </Surface>
  );
};

export default HomePageCaseMobile;
