import { gql, useLazyQuery } from "@apollo/client";
import {
  InputCheckbox,
  InputDate,
  InputDropdown,
  InputSSN,
  InputText,
  InputTextarea,
} from "@heart/components";
import I18n from "i18n-js";
import { join, isEmpty } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import useFeatureFlag from "@lib/useFeatureFlag";

import { GENDERS_OPEN_TO_OPTIONS } from "@root/constants";

import PotentialAgencyHumanMatches from "./PotentialAgencyHumanMatches";

const { t } = translationWithRoot("agency_human.form.personal_info");

export const AgencyHumanFuzzyFindByNameQuery = gql`
  query AgencyHumanFuzzyFindByNameQuery($name: String!) {
    agencyHumans(name: $name) {
      id
      fullName
      firstName
      middleName
      lastName
      suffix
      dateOfBirth
      isEstimatedDateOfBirth
      estimatedDateOfBirth
      dateOfDeath
      ssn
      gender
      ethnicities
      linkToView
    }
  }
`;

/** All the form inputs for the Personal Information section of the
 * Add Connection form in Family Finding, which creates a new Agency Human
 * and the Edit Connection form in Family Finding, which edits an existing
 * Agency Human
 */
export const PersonalInfoSection = ({
  keystoneAgencyHumanId,
  editingExistingAgencyHuman,
  formState,
  setFormAttribute,
  setFormState,
}) => {
  const { flag: ffEstimatedDob } = useFeatureFlag("ff_estimated_dob_09_2024");

  const [checkForExistingAgencyHumans, { data: potentialAgencyHumanMatches }] =
    useLazyQuery(AgencyHumanFuzzyFindByNameQuery, {
      variables: {
        name: join(
          [
            formState.firstName,
            formState.middleName,
            formState.lastName,
            formState.suffix,
          ],
          " "
        ).trim(),
      },
    });

  const onBlur = editingExistingAgencyHuman
    ? undefined
    : () => checkForExistingAgencyHumans();

  return (
    <Fragment>
      <InputText
        required={true}
        label={t("first_name")}
        value={formState.firstName}
        onChange={setFormAttribute("firstName")}
        onBlur={onBlur}
      />
      <InputText
        label={t("middle_name")}
        value={formState.middleName}
        onChange={setFormAttribute("middleName")}
        onBlur={onBlur}
      />
      <InputText
        label={t("last_name")}
        value={formState.lastName}
        onChange={setFormAttribute("lastName")}
        onBlur={onBlur}
      />
      <InputText
        label={t("suffix")}
        value={formState.suffix}
        onChange={setFormAttribute("suffix")}
        onBlur={onBlur}
      />
      <InputText
        label={t("nickname")}
        value={formState.nickname}
        onChange={setFormAttribute("nickname")}
        onBlur={onBlur}
      />
      <If condition={!isEmpty(potentialAgencyHumanMatches?.agencyHumans)}>
        <PotentialAgencyHumanMatches
          keystoneAgencyHumanId={keystoneAgencyHumanId}
          matches={potentialAgencyHumanMatches?.agencyHumans}
          setFormAttribute={setFormAttribute}
        />
      </If>
      <InputDate
        label={t("date_of_birth")}
        value={
          formState.isEstimatedDateOfBirth
            ? formState.estimatedDateOfBirth
            : formState.dateOfBirth
        }
        onChange={
          formState.isEstimatedDateOfBirth
            ? setFormAttribute("estimatedDateOfBirth")
            : setFormAttribute("dateOfBirth")
        }
        maxDate={DateTime.local().toFormat("yyyy-MM-dd")}
      />
      <If condition={ffEstimatedDob}>
        <InputCheckbox
          label={t("is_estimated_dob")}
          value={formState.isEstimatedDateOfBirth}
          onChange={checked => {
            const date =
              formState.dateOfBirth || formState.estimatedDateOfBirth;
            setFormState({
              ...formState,
              dateOfBirth: checked ? null : date,
              estimatedDateOfBirth: checked ? date : null,
              isEstimatedDateOfBirth: checked,
            });
          }}
        />
      </If>
      <InputCheckbox
        label={t("person_is_deceased")}
        value={formState.isDeceased}
        onChange={checked => {
          setFormState({
            ...formState,
            isDeceased: checked,
            dateOfDeath: checked ? formState.dateOfDeath : null,
            deathDetails: checked ? formState.deathDetails : "",
          });
        }}
      />
      <If condition={formState.isDeceased}>
        <InputDate
          label={t("date_of_death")}
          value={formState.dateOfDeath}
          onChange={setFormAttribute("dateOfDeath")}
          maxDate={DateTime.local().toFormat("yyyy-MM-dd")}
        />
        <InputTextarea
          label={t("death_details")}
          value={formState.deathDetails}
          onChange={setFormAttribute("deathDetails")}
        />
      </If>
      <InputSSN
        label={t("ssn")}
        value={formState.ssn}
        onChange={setFormAttribute("ssn")}
      />
      <InputDropdown
        label={t("gender")}
        value={formState.gender}
        values={GENDERS_OPEN_TO_OPTIONS.map(gender => ({
          label: I18n.t(`activerecord.enums.common.gender.${gender}`),
          value: gender,
        }))}
        onChange={setFormAttribute("gender")}
      />
      <InputCheckbox label="Create an Application for this Connection" />
    </Fragment>
  );
};
PersonalInfoSection.propTypes = {
  /** The agency human ID for the keystone agency human, if in the context
   * of relationships to that keystone agency human. This ensures the fuzzy
   * find logic doesn't offer the keystone agency human as a potential match
   */
  keystoneAgencyHumanId: PropTypes.string,
  /** Set to true when editing an existing human, toggles off the
   * funtionality that suggests existing agency humans
   */
  editingExistingAgencyHuman: PropTypes.bool,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
  setFormAttribute: PropTypes.func.isRequired,
};

export default PersonalInfoSection;
