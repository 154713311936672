import {
  Flex,
  Surface,
  Text,
  InputText,
  Button,
  InputDate,
  InputSSN,
  InputFilterable,
  SurfaceForm,
  Actions,
  DetailsTable,
  InputDropdown,
  InputRadioGroup,
  InputTextarea,
  If,
} from "@heart/components";
import {
  displaySelectedEthnicities,
  displaySelectedRaces,
} from "@utils/ethnicitiesAndRaces";
import LANGUAGES_OPTIONS from "@utils/languages";
import post from "@utils/post";
import protectedTribes from "@utils/protectedTribes";
import { useState } from "react";

import EthnicityAndRaceWrapper from "@components/intake_ethnicity/EthnicityAndRaceWrapper";

import { usePersonInformation } from "../PersonContext";
import styles from "../PersonProfilePage.module.scss";

const GENDER_IDENTITY_OPTIONS = {
  female: "Female",
  male: "Male",
  non_binary: "Non-binary",
  transgender: "Transgender",
  have_not_asked_child_too_young: "Have not asked child (child too young)",
  have_not_asked_child_other_reason: "Have not asked child (other reason)",
  prefer_not_to_answer: "Prefer not to answer",
  not_listed_above: "Not listed above",
};

const DISABILITY_TYPES_OPTIONS = {
  ssi: "Supplemental Security Income (SSI)",
  ssdi: "Social Security Disability Insurance (SSDI)",
  sdi: "State Disability Insurance (SDI)",
  long_short_term_disability: "Long-term and Short-term Disability Insurance",
  veterans_disability: "Veteran's Disability",
  workers_compensation_disability: "Worker's Compensation Disability",
};

const CITIZENSHIP_STATUS_OPTIONS = {
  us_citizen: "US Citizen",
  alien: "Alien (with Alien number)",
  unqualified_alien: "Unqualified Alien",
};

const Demographics = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { formState, updateFormState } = usePersonInformation();
  const [disabilityStatus, setDisabilityStatus] = useState(
    formState.person.disabilityStatus
  );

  const [citizenshipStatus, setCitizenshipStatus] = useState(
    formState.person.citizenshipStatus
  );

  const [sex, setSex] = useState(formState.person.sex);
  const [isTribalAffiliated, setIsTribalAffiliated] = useState(
    formState.person.tribalAffiliation
  );

  const handleSave = e => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const data = {
      firstName: formData.get("firstName"),
      middleName: formData.get("middleName"),
      lastName: formData.get("lastName"),
      suffix: formData.get("suffix"),
      alsoKnownAs: formData.get("alsoKnownAs"),
      historicalNames: formData.get("historicalNames"),
      ssn: formData.get("ssn"),
      medicaidNumber: formData.get("medicaidNumber"),
      dob: formData.get("dob"),
      sex: formData.get("sex"),
      genderIdentity: formData.get("genderIdentity"),
      primaryLanguage: formData.get("primaryLanguage"),
      secondaryLanguage: formData.get("secondaryLanguage"),
      needInterpreter: formData.get("needInterpreter"),
      physicalDescriptor: formData.get("physicalDescriptor"),
      disabilityStatus: formData.get("disabilityStatus"),
      disabilityTypes: formData.getAll("disabilityTypes"),
      juvenileCourtNumber: formData.get("juvenileCourtNumber"),
      circuitNumber: formData.get("circuitNumber"),
      citizenshipStatus: formData.get("citizenshipStatus"),
      entryDateInsDocumentation: formData.get("entryDateInsDocumentation"),
      isPregnant: formData.get("isPregnant"),
      race: formData.get("race"),
      ethnicity: formData.get("ethnicity"),
      everFatheredBoreChild: formData.get("everFatheredBoreChild"),
      childPlacedTogether: formData.get("childPlacedTogether"),
      tribalAffiliation: formData.get("tribalAffiliation"),
      tribeBandName: formData.get("tribeBandName"),
      tribalIdentification: formData.get("tribalIdentification"),
    };

    post(
      `/persons/${formState.person.id}`,
      { ...formState.person, ...data },
      response => {
        if (response.success) {
          updateFormState({ person: { ...formState.person, ...data } });
          setIsEditing(false);
        }
      }
    );
  };

  if (isEditing) {
    return (
      <SurfaceForm
        actions={<Actions primaryText="Save" />}
        title="Demographics"
        hideTitle
        sectionId="demographics"
        onSubmit={handleSave}
      >
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Demographics
        </Text>
        <Flex column gap="200" fullWidth>
          <Flex row gap="200" className={styles.mobileColumn}>
            <Flex grow>
              <InputText
                label="First Name"
                required
                name="firstName"
                fullWidth
                value={formState.person.firstName}
              />
            </Flex>
            <Flex grow>
              <InputText
                label="Middle Name"
                name="middleName"
                fullWidth
                value={formState.person.middleName}
              />
            </Flex>
            <Flex grow>
              <InputText
                label="Last Name"
                required
                name="lastName"
                fullWidth
                value={formState.person.lastName}
              />
            </Flex>
          </Flex>
          <InputText
            label="Suffix"
            name="suffix"
            value={formState.person.suffix}
          />
          <InputText
            label="Also known as"
            name="alsoKnownAs"
            value={formState.person.alsoKnownAs}
          />
          <InputText
            label="Historical Names"
            name="historicalNames"
            value={formState.person.historicalNames}
          />
          <InputSSN label="SSN" name="ssn" value={formState.person.ssn || ""} />
          <InputText
            label="Medicaid Number"
            name="medicaidNumber"
            value={formState.person.medicaidNumber}
          />
          <Flex row gap="200" className={styles.mobileColumn}>
            <InputDate
              required
              label="Date of birth"
              name="dob"
              value={formState.person.dob}
            />

            <InputFilterable
              id="sex"
              label="Sex"
              name="sex"
              defaultValue={{ label: sex, value: sex }}
              onChange={opt => setSex(opt.value)}
              values={[
                {
                  label: "Male",
                  value: "Male",
                },
                {
                  label: "Female",
                  value: "Female",
                },
                {
                  label: "Intersex",
                  value: "Intersex",
                },
                {
                  label: "Unknown",
                  value: "Unknown",
                },
              ]}
            />
          </Flex>
          <InputDropdown
            id="genderIdentity"
            label="Gender Identity"
            name="genderIdentity"
            value={formState.person.genderIdentity}
            onChange={value => updateFormState({ genderIdentity: value })}
            values={Object.entries(GENDER_IDENTITY_OPTIONS).map(
              ([key, value]) => [value, key]
            )}
          />

          <EthnicityAndRaceWrapper
            useRacesInput
            ethnicitiesInputName="ethnicity"
            racesInputName="race"
            isRequired
            selectedEthnicities={
              formState.person.ethnicity
                ? JSON.parse(formState.person.ethnicity)
                : {}
            }
            selectedRaces={
              formState.person.race ? JSON.parse(formState.person.race) : {}
            }
          />

          <InputRadioGroup
            id="tribalAffiliation"
            name="tribalAffiliation"
            label="Is this person an enrolled member of a tribe or band?"
            required
            value={isTribalAffiliated}
            onChange={setIsTribalAffiliated}
            values={["Yes", "No", "Unknown"]}
          />

          <If condition={isTribalAffiliated === "Yes"}>
            <InputFilterable
              id="tribeBandName"
              label="Tribe or Band Name"
              description="If the tribe or band is not listed, please contact Binti Support"
              name="tribeBandName"
              defaultValue={{
                label: formState.person.tribeBandName,
                value: formState.person.tribeBandName,
              }}
              values={protectedTribes.map(tribe => ({
                label: tribe,
                value: tribe,
              }))}
            />

            <InputText
              label="Tribal Identification (CDIB, CIB, or equivalent)"
              name="tribalIdentification"
              value={formState.person.tribalIdentification}
            />
          </If>

          <InputDropdown
            id="primaryLanguage"
            label="Primary Language"
            name="primaryLanguage"
            value={formState.person.primaryLanguage}
            values={Object.entries(LANGUAGES_OPTIONS).map(([key, value]) => [
              value,
              key,
            ])}
          />
          <InputDropdown
            id="secondaryLanguage"
            label="Secondary Language"
            name="secondaryLanguage"
            value={formState.person.secondaryLanguage}
            values={Object.entries(LANGUAGES_OPTIONS).map(([key, value]) => [
              value,
              key,
            ])}
          />
          <InputRadioGroup
            id="needInterpreter"
            label="Need interpreter"
            required
            name="needInterpreter"
            value={formState.person.needInterpreter}
            values={["Yes", "No"]}
          />
          <InputTextarea
            label="Physical descriptor"
            name="physicalDescriptor"
            value={formState.person.physicalDescriptor}
          />
          <InputRadioGroup
            id="disabilityStatus"
            label="Disability status"
            required
            name="disabilityStatus"
            value={disabilityStatus}
            onChange={setDisabilityStatus}
            values={["Yes", "No"]}
          />
          <If condition={disabilityStatus === "Yes"}>
            <InputFilterable
              id="disabilityTypes"
              isMulti
              label="Disability Types"
              name="disabilityTypes"
              defaultValue={formState.person.disabilityTypes?.map(type => ({
                label: DISABILITY_TYPES_OPTIONS[type],
                value: type,
              }))}
              values={Object.entries(DISABILITY_TYPES_OPTIONS).map(
                ([value, label]) => ({
                  label,
                  value,
                })
              )}
            />
          </If>
          <Flex row gap="200" className={styles.mobileColumn}>
            <InputText
              label="Juvenile Court number"
              name="juvenileCourtNumber"
              disabled
            />
            <InputText label="Circuit Number" name="circuitNumber" disabled />
          </Flex>
          <InputDropdown
            id="citizenshipStatus"
            label="Citizenship status"
            name="citizenshipStatus"
            value={citizenshipStatus}
            onChange={setCitizenshipStatus}
            values={Object.entries(CITIZENSHIP_STATUS_OPTIONS).map(
              ([key, value]) => [value, key]
            )}
          />
          <If condition={citizenshipStatus === "alien"}>
            <InputDate
              required
              label="Entry Date for INS documentation"
              name="entryDateInsDocumentation"
              value={formState.person.dob}
            />
          </If>
          <If condition={sex === "Female" || sex === "Intersex"}>
            <InputRadioGroup
              id="isPregnant"
              label="Pregnant?"
              required
              name="isPregnant"
              value={formState.person.isPregnant}
              values={["Yes", "No"]}
            />
          </If>
          <InputRadioGroup
            id="everFatheredBoreChild"
            label="Ever Fathered/Bore a child?"
            required
            name="everFatheredBoreChild"
            value={formState.person.everFatheredBoreChild}
            values={["Yes", "No"]}
          />
          <InputText
            label="Child/children placed together?"
            name="childPlacedTogether"
            disabled
          />
        </Flex>
      </SurfaceForm>
    );
  }

  return (
    <Surface title="Demographics" hideTitle sectionId="demographics">
      <Flex justify="space-between" align="center">
        <Text textStyle="emphasis-200" textColor="neutral-600">
          Demographics
        </Text>
        <Button variant="secondary" onClick={() => setIsEditing(true)}>
          Edit
        </Button>
      </Flex>
      <Flex gap="200" align="start" className={styles.mobileColumn}>
        <DetailsTable
          details={[
            {
              label: "First Name",
              value: formState.person.firstName,
            },
            {
              label: "Middle Name",
              value: formState.person.middleName,
            },
            {
              label: "Last Name",
              value: formState.person.lastName,
            },
            {
              label: "Suffix",
              value: formState.person.suffix,
            },
            {
              label: "Also known as",
              value: formState.person.alsoKnownAs,
            },
            {
              label: "Historical Names",
              value: formState.person.historicalNames,
            },
            {
              label: "SSN",
              value: formState.person.ssn,
            },
            {
              label: "Medicaid Number",
              value: formState.person.medicaidNumber,
            },
            {
              label: "Date of birth",
              value: formState.person.dob,
            },
            {
              label: "Sex",
              value: formState.person.sex,
            },
            {
              label: "Gender Identity",
              value:
                GENDER_IDENTITY_OPTIONS[formState.person.genderIdentity] ||
                formState.person.genderIdentity,
            },
            {
              label: "Ethnicity",
              value: formState.person.ethnicity
                ? displaySelectedEthnicities(formState.person.ethnicity)
                : null,
            },
            {
              label: "Race",
              value: formState.person.race
                ? displaySelectedRaces(formState.person.race)
                : null,
            },
            {
              label: "Member of a tribe or band",
              value:
                formState.person.tribalAffiliation === "No"
                  ? ""
                  : `${formState.person.tribalAffiliation}. ${
                      formState.person.tribeBandName || ""
                    }`,
            },
            formState.person.tribalIdentification
              ? {
                  label: "Tribal Identification",
                  value: formState.person.tribalIdentification,
                }
              : {},
            {
              label: "Primary Language",
              value:
                LANGUAGES_OPTIONS[formState.person.primaryLanguage] ||
                formState.person.primaryLanguage,
            },
            {
              label: "Secondary Language",
              value:
                LANGUAGES_OPTIONS[formState.person.secondaryLanguage] ||
                formState.person.secondaryLanguage,
            },
          ]}
        />
        <DetailsTable
          details={[
            {
              label: "Need interpreter",
              value: formState.person.needInterpreter,
            },
            {
              label: "Physical descriptor",
              value: formState.person.physicalDescriptor,
            },
            {
              label: "Disability status",
              value: formState.person.disabilityStatus,
            },
            {
              label: "Disability Types",
              value: formState.person.disabilityTypes
                ?.map(type => DISABILITY_TYPES_OPTIONS[type] || type)
                .join(", "),
            },
            {
              label: "Juvenile Court number",
              value: formState.person.juvenileCourtNumber,
            },
            {
              label: "Circuit Number",
              value: formState.person.circuitNumber,
            },
            {
              label: "Citizenship status",
              value:
                CITIZENSHIP_STATUS_OPTIONS[
                  formState.person.citizenshipStatus
                ] || formState.person.citizenshipStatus,
            },
            {
              label: "Entry Date for INS documentation",
              value: formState.person.entryDateInsDocumentation,
            },
            {
              label: "Pregnant",
              value: formState.person.isPregnant,
            },
            {
              label: "Ever Fathered/Bore a child",
              value: formState.person.everFatheredBoreChild,
            },
            {
              label: "Child/children placed together",
              value: formState.person.childPlacedTogether,
            },
          ]}
        />
      </Flex>
    </Surface>
  );
};

export default Demographics;
