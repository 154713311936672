import { ContentTabs, PageContainer, Surface } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import ContactLogsTable from "@components/family_finding/contact_logs/ContactLogsTable";

import RelationshipsTable from "./RelationshipsTable";
import RelationshipsTableOrGenogram from "./RelationshipsTableOrGenogram";

const { t } = translationWithRoot("family_finding.relationships_dashboard");

/** The foundational component for the Family Finding relationships dashboard
 *
 * This component contains the tabs for the relationships table, contact logs table,
 * and potential connections table
 */
const RelationshipsDashboard = ({
  childAgencyHumanId,
  addPersonFormPath,
  downloadRelationshipsCsvPath,
  editContactLogBasePath,
  downloadContactLogsCsvPath,
}) => (
  <PageContainer fullWidth>
    <Surface hideTitle title={t("relationships_dashboard")}>
      <ContentTabs
        tabs={[
          {
            title: t("relationships"),
            contents: (
              <RelationshipsTableOrGenogram
                tab="relationships"
                addPersonFormPath={addPersonFormPath}
                childAgencyHumanId={childAgencyHumanId}
                downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
              />
            ),
          },
          {
            title: t("contact_logs"),
            contents: (
              <ContactLogsTable
                childAgencyHumanId={childAgencyHumanId}
                editContactLogBasePath={editContactLogBasePath}
                downloadContactLogsCsvPath={downloadContactLogsCsvPath}
              />
            ),
          },
          {
            title: t("potential_connections"),
            contents: (
              <RelationshipsTable
                tab="potentialConnections"
                addPersonFormPath={addPersonFormPath}
                keystoneAgencyHumanId={childAgencyHumanId}
                downloadRelationshipsCsvPath={downloadRelationshipsCsvPath}
                ffAddPotentialConnections012024={true}
              />
            ),
          },
          /** we can remove this filter when we remove the potential connections
           * feature flag as well */
        ].filter(Boolean)}
      />
    </Surface>
  </PageContainer>
);
RelationshipsDashboard.propTypes = {
  addPersonFormPath: PropTypes.string.isRequired,
  childAgencyHumanId: PropTypes.string.isRequired,
  downloadRelationshipsCsvPath: PropTypes.string.isRequired,
  editContactLogBasePath: PropTypes.string.isRequired,
  downloadContactLogsCsvPath: PropTypes.string.isRequired,
};

export default RelationshipsDashboard;
